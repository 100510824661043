import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  Box,
  CircularProgress
} from '@mui/material'
import { Close as CloseIcon, Wallet, Language } from '@mui/icons-material'
import { apiRequest } from '../../helpers/request'
import { PaymentData, PaymentResponse, TitleType } from '../../types/payment'
import { toast } from 'react-toastify'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id: number
  getOrders?: () => Promise<void>
}

const PaymentMethodsModal: React.FC<Props> = ({
  open,
  setOpen,
  id,
  getOrders
}) => {
  const [selectedMethod, setSelectedMethod] = useState<TitleType>('wallet')
  const [data, setData] = useState<PaymentData[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleClose = () => setOpen(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value as TitleType)
  }

  const getPayments = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<PaymentResponse>(
      `/api/getPaymentMethod`,
      {
        method: 'GET'
      }
    )
    setLoading(false)

    if (data?.status) {
      setData(data.data)
    } else {
      toast.error(error || 'Get payment list fails. Please try again.')
    }
  }

  const payment = async () => {
    const { data, error } = await apiRequest<PaymentResponse>(`/api/payment`, {
      method: 'POST',
      body: {
        order_id: id,
        payment_type: selectedMethod
      }
    })
    handleClose()

    if (data?.status) {
      toast.success(data.message)
      getOrders?.()
    } else {
      toast.error(error || 'Get payment list fails. Please try again.')
    }
  }

  useEffect(() => {
    getPayments()
  }, [])

  return (
    <Dialog open={open} onClose={handleClose} dir="rtl">
      {loading ? (
        <Box p={16}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle>
            روش های پرداخت
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', left: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <RadioGroup value={selectedMethod} onChange={handleChange}>
              {data.map((ele, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  mb={2}
                  p={1}
                  borderRadius="8px"
                  bgcolor="#f1f3fe">
                  <Radio
                    value={ele.title}
                    color="primary"
                    disabled={!ele.status}
                  />
                  <Typography sx={{ marginRight: 1 }}>
                    {ele.subtitle}
                  </Typography>
                  {index === 0 ? (
                    <Wallet sx={{ color: '#1a73e8', marginLeft: 'auto' }} />
                  ) : (
                    <Language sx={{ color: '#1a73e8', marginLeft: 'auto' }} />
                  )}
                </Box>
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={payment} color="primary">
              تایید
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default PaymentMethodsModal

import Header from '../../components/Header'
import {
  Notification,
  NotificationResponse
} from '../../types/notificationItem'
import NotificationItem from '../../components/notificationItem'
import { useStyles } from './styles/index'
import { apiRequest, getToken } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

function Notifications() {
  const classes = useStyles()
  const token = getToken()
  const [items, setItems] = useState<Notification[]>([])
  // const items: NotificationData[] = [
  //   {
  //     date: '2024-10-11',
  //     items: [
  //       {
  //         past: '2H',
  //         title: 'طلای خرید شده ارسال شد',
  //         subTitle:
  //           'طلای خریداری شده شما به شماره فاکتور 4519 به مبدا ارسال شد',
  //         unread: false
  //       },
  //       {
  //         past: '6H',
  //         title: 'طلای خرید شده ارسال شد',
  //         subTitle:
  //           'طلای خریداری شده شما به شماره فاکتور 2314 به مبدا ارسال شد',
  //         unread: true
  //       }
  //     ]
  //   },
  //   {
  //     date: '2024-09-11',
  //     items: [
  //       {
  //         past: '11D',
  //         title: 'طلای شما در حال تعمیر شد',
  //         subTitle: 'طلای شما به شماره فاکتور 9811 در حال تعمیر می‌باشد',
  //         unread: false
  //       }
  //     ]
  //   },
  //   {
  //     date: '2024-08-11',
  //     items: [
  //       {
  //         past: '22D',
  //         title: 'طلای شما در حال ساخت می‌باشد',
  //         subTitle: 'طلای شما به شماره فاکتور 1198 در حال ساخت می‌باشد',
  //         unread: false
  //       }
  //     ]
  //   }
  // ]

  const GetNotificationList = async () => {
    const { data, error } = await apiRequest<NotificationResponse>(
      '/api/notifications',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setItems(data.data)
    } else {
      toast.error(error || 'Notification failed. Please try again.')
    }
  }

  useEffect(() => {
    token && GetNotificationList()
  }, [token])

  return (
    <div className={classes.root}>
      <Header title={'اعلانات'} />
      {!token && <Typography>{'هیچ اعلانی وجود ندارد'}</Typography>}
      {items.map((item, index) => (
        <NotificationItem key={index} data={item} />
      ))}
    </div>
  )
}

export default Notifications

import { Button, IconButton, Modal, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Header from '../Header'
import Footer from '../Footer'
import { useStyles } from './styles/index'

type FollowUpProps = {
  action: string
  number: number
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function FollowUp({
  action,
  number,
  isOpen,
  setIsOpen
}: FollowUpProps) {
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      dir="rtl"
      className={classes.modal}
      onClose={() => {
        setIsOpen(false)
      }}>
      <div className={classes.root}>
        <IconButton>
          <CheckCircleOutlineIcon className={classes.icon} />
        </IconButton>
        <div className={classes.texts}>
          <Typography variant="h5" className={classes.textColor}>
            {`درخواست ${action} طلای`}
            <br />
            {`شما با موفقیت ثبت شد`}
          </Typography>
          <Typography
            variant="h5"
            className={[classes.textColor, classes.number].join(' ')}>
            {'شناسه پیگیری: '}
            {number}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => setIsOpen(false)}
            variant="contained"
            className={classes.button}>
            <Typography variant="body1">{'خروج'}</Typography>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

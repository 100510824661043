import Header from '../../components/Header'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import {
  Order,
  OrderResponse,
  OrderStatus,
  OrderStatusValue
} from '../../types/orders'
import OrderItem from '../../components/OrderItem'
import { Typography } from '@mui/material'
import LoadingScreen from '../../components/LoadingScreen'
import { useStyles } from './styles/index'

function Orders() {
  const classes = useStyles()
  const [data, setData] = useState<Order[]>([])
  const [status, setStatus] = useState<OrderStatusValue>('pending')
  const [loading, setLoading] = useState(false)

  const statusList: OrderStatus[] = [
    {
      label: 'منتظر تایید',
      value: 'pending'
    },
    {
      label: 'منتظر پرداخت',
      value: 'accepted'
    },
    {
      label: 'لغو شده',
      value: 'canceled'
    },
    {
      label: 'تکمیل شده',
      value: 'completed'
    }
  ]

  const getOrders = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<OrderResponse>(
      `/api/user-orders?status=${status}`,
      {
        method: 'GET'
      }
    )
    setLoading(false)

    if (data?.status) {
      setData(data.data)
    } else {
      toast.error(error || 'Get order list fails. Please try again.')
    }
  }

  useEffect(() => {
    getOrders()
  }, [status])

  return (
    <div className={classes.root}>
      <Header title={'لیست سفارشات'} />
      <div className={classes.options}>
        {statusList.map((ele, index) => (
          <div
            key={index}
            className={[
              classes.option,
              ele.value === status && classes.selectedOption
            ].join(' ')}
            onClick={() => {
              setStatus(ele.value)
            }}>
            <Typography variant="caption">{ele.label}</Typography>
          </div>
        ))}
      </div>
      <div className={classes.itemsContainer}>
        {data &&
          data.map((item, index) => (
            <OrderItem key={index} item={item} getOrders={getOrders} />
          ))}
      </div>
      <LoadingScreen numberOfCircles={4} open={loading} />
    </div>
  )
}

export default Orders

import { Button, Typography } from '@mui/material'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import { useEffect, useState } from 'react'
import { MeltedStatus } from '../../types/meltedGold'
import { useStyles } from './styles/index'
import PaymentMethodsModal from '../PaymentModal'

type Props = {
  data: MeltedStatus
  setIsConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>
  orderNumber: number
}

function ConfirmationCart({ data, setIsConfirmOpen, orderNumber }: Props) {
  const classes = useStyles()
  const [timeLeft, setTimeLeft] = useState<number>(180)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsConfirmOpen(false)
      return // Stop if time is up
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(intervalId) // Cleanup on unmount
  }, [timeLeft])

  // Convert seconds to MM:SS format
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.title}>
        {data.seller}
      </Typography>
      <div className={classes.reviewSection}>
        <Typography className={classes.titleSmall}>{data.product}</Typography>
        <div className={classes.timerAndIcon}>
          <Typography className={classes.titleSmall}>
            {formatTime(timeLeft)}
          </Typography>
          <div className={[classes.titleSmall, classes.icon].join(' ')}>
            <AccessAlarmIcon />
          </div>
        </div>
      </div>
      <div className={classes.status}>
        <Typography className={classes.titleSmall}>{'وضعیت: '}</Typography>
        &nbsp;
        <Typography className={classes.titleConfirm}>{data.total}</Typography>
      </div>
      <div className={classes.buttonPaymentContainer}>
        <Button
          onClick={() => {
            setOpenModal(true)
          }}
          variant="contained"
          disabled={data.status === 'pending'}
          className={classes.buttonPayment}>
          <Typography variant="body1">{'پرداخت'}</Typography>
        </Button>
      </div>
      {openModal && (
        <PaymentMethodsModal
          open={openModal}
          setOpen={setOpenModal}
          id={orderNumber}
        />
      )}
    </div>
  )
}

export default ConfirmationCart

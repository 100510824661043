import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.main,
    padding: '2rem 4rem',
    borderRadius: 8
  },
  icon: {
    fontSize: '96px!important',
    color: `${theme.palette.primary.contrastText}!important`
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textColor: {
    color: `${theme.palette.primary.contrastText}!important`
  },
  number: {
    margin: '2rem 0rem!important'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    borderRadius: '1rem!important',
    background: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.main}!important`
  }
}))

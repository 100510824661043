import Header from '../../components/Header'
import Stepper from '../../components/stepper/stepper'
import {
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import UploadBox from '../../components/uploadBox'
import FollowUp from '../../components/followUp'
import soviet from '../../assets/soviet-union.svg'
import { useState } from 'react'
import { useStyles } from './styles/index'

function CreateGold() {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [isOpen, setIsOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['ثبت درخواست', 'درحال تعمیر', 'تحویل', 'اتمام']

  const content = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className={classes.uploadSection}>
              <Typography>{'1. ابتدا عکس طلای خود را پیوست کنید.'}</Typography>
              <UploadBox />
            </div>
            <div className={classes.uploadSection}>
              <Typography>{'2.نیازمندی ساخت طلای خود را بنویسید.'}</Typography>
              <TextField
                className={classes.input}
                placeholder={
                  'مثال: لطفا این طلا را که از یک ژورنال ایتالیایی پیدا کردم برایم بسازید.'
                }
                value={''}
                onChange={(e) => {
                  console.log(e)
                }}
                multiline
                rows={5}
                fullWidth
                inputProps={{
                  root: { fontSize: 14 }
                }}
              />
            </div>
            <div
              className={[
                classes.buttonContainer,
                isSmall && classes.buttonContainerSmall
              ].join(' ')}>
              <Button
                onClick={() => setIsOpen(!isOpen)}
                variant="contained"
                className={classes.button}>
                <Typography variant="body1">{'ثبت درخواست'}</Typography>
              </Button>
            </div>
          </>
        )
      case 1:
        return (
          <div className={classes.progressStep}>
            <Typography className={classes.progressStepText}>
              {'طلای موردنظر در حال ساخت می‌باشد.'}
            </Typography>
            <img
              src={soviet}
              alt="progress-image"
              className={classes.progressStepImg}
            />
          </div>
        )
      default:
        break
    }
  }

  return (
    <div className={classes.root}>
      <Header existIcons title={'ساخت طلا'} />
      <div>
        <Stepper activeStep={activeStep} steps={steps} />
        {content(activeStep)}
      </div>
      <FollowUp
        action={'ساخت'}
        number={14205}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}

export default CreateGold

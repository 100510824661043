import { Button, Typography } from '@mui/material'
import { addCommaToPrice, removeCommaFromPrice } from '../../helpers/numbers'
import { useEffect, useState } from 'react'
import ConfirmationCart from '../ConfirmationCart'
import Preview from '../Preview'
// import { useWebSocket } from '../../helpers/webSocket'
import { MeltedGoldStatusResponse, MeltedStatus } from '../../types/meltedGold'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'

type Props = {
  buy: boolean
  id: number
  price: number
  title: string
}

function CartItem({ buy, id, price, title }: Props) {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [orderNumber, setOrderNumber] = useState(0)
  const [priceState, setPriceState] = useState<number | string>('')
  const [weight, setWeight] = useState<number | string>('')
  const [data, setData] = useState<MeltedStatus>({
    seller: 'در انتظار تایید فروشنده',
    product: 'در حال بررسی سفارش',
    total: 'در انتظار',
    status: 'pending'
  })

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const rawPrice = parseFloat(removeCommaFromPrice(inputValue)) || ''
    setPriceState(rawPrice)

    if (rawPrice !== '') {
      const calculatedWeight = (rawPrice / price).toFixed(4) // Limit weight to 4 decimal places
      setWeight(calculatedWeight)
    } else {
      setWeight('')
    }
  }

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    // Allow input to be numeric with up to four decimal places
    const decimalPattern = /^\d*\.?\d{0,4}$/
    if (!decimalPattern.test(inputValue)) {
      return // Ignore invalid input
    }

    setWeight(inputValue) // Temporarily store the raw input value

    // Only calculate and set the price when inputValue is a valid number
    const rawWeight = parseFloat(removeCommaFromPrice(inputValue))
    if (!isNaN(rawWeight)) {
      const calculatedPrice = rawWeight * price // Limit price to 4 decimal places
      setPriceState(calculatedPrice)
    } else {
      setIsConfirmOpen(false)
      setPriceState('')
    }
  }

  // // Callback to handle incoming WebSocket events
  // const handleNewStatus = (data: any) => {
  //   console.log({ data })

  //   // switch (data) {
  //   //   case value:

  //   //     break;

  //   //   default:
  //   //     break;
  //   // }
  //   setData({
  //     seller: 'تایید شده توسط فروشنده',
  //     product: 'سفارش بررسی شد',
  //     total: 'تایید شده',
  //     status: 'success'
  //   })
  // }

  // useWebSocket({
  //   channelName: 'ordermc', // Channel name defined in Laravel
  //   eventName: 'OrderMcCreate', // Event name in Laravel
  //   onEvent: handleNewStatus
  // })

  const checkStatus = async () => {
    const { data, error } = await apiRequest<MeltedGoldStatusResponse>(
      `/api/order-status?id=${orderNumber}`,
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      if (data.data.status === 'accepted') {
        setData({
          seller: 'تایید شده توسط فروشنده',
          product: 'سفارش بررسی شد',
          total: 'تایید شده',
          status: 'accepted'
        })
        setOrderNumber(0)
      }
    } else {
      toast.error(error || 'Buy failed. Please try again.')
    }
  }

  useEffect(() => {
    if (orderNumber && isConfirmOpen) {
      const intervalId = setInterval(checkStatus, 20000) // Call every 10 seconds

      return () => clearInterval(intervalId) // Clear interval on component unmount or stop
    }
  }, [orderNumber, isConfirmOpen])

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.priceSection}>
          <div className={classes.priceSectionRight}>
            <Typography className={classes.titleSmall}>
              {buy ? 'قیمت خرید' : 'قیمت فروش'}
            </Typography>
            <Typography className={classes.titleSmall}>
              {'(هر گرم طلای 18 عیار)'}
            </Typography>
          </div>
          <div className={classes.priceSectionLeft}>
            <Typography className={classes.titleMedium}>
              {addCommaToPrice(price)}
            </Typography>
            <Typography
              variant="body1"
              className={[classes.titleMedium, classes.currency].join(' ')}>
              {'تومان'}
            </Typography>
          </div>
        </div>
        <div className={classes.inputs}>
          <input
            className={classes.price}
            value={priceState ? addCommaToPrice(priceState) : ''}
            placeholder={'مبلغ خرید به تومان'}
            onChange={handlePriceChange}
            type="text"
          />
          <input
            className={classes.price}
            value={weight ? addCommaToPrice(weight) : ''}
            placeholder={'مقدار طلا به گرم'}
            onChange={handleWeightChange}
            type="text"
          />
        </div>
        {!isConfirmOpen ? (
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            className={buy ? classes.buttonBuy : classes.buttonSell}
            disabled={!weight}>
            <Typography variant="body1">{buy ? 'خرید' : 'فروش'}</Typography>
          </Button>
        ) : (
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            className={classes.hide}>
            <Typography variant="body1">{buy ? 'خرید' : 'فروش'}</Typography>
          </Button>
        )}
      </div>
      {isModalOpen && (
        <Preview
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          buy={buy}
          fee={price}
          weight={weight as number}
          totalPrice={priceState as number}
          id={id}
          setOrderNumber={setOrderNumber}
        />
      )}
      {isConfirmOpen && (
        <ConfirmationCart
          data={data}
          setIsConfirmOpen={setIsConfirmOpen}
          orderNumber={orderNumber}
        />
      )}
    </>
  )
}

export default CartItem

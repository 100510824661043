import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1.3rem',
    paddingRight: '0.4rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '1.5rem'
  },
  melted: {
    backgroundColor: theme.palette.primary.light // Light red
  },
  coin: {
    backgroundColor: '#B2B266' // Light red
  },
  buy: {
    // backgroundColor: theme.palette.secondary.main // Light green
    backgroundColor: `${theme.palette.grey[300]}`
  },
  repair: {
    // backgroundColor: theme.palette.success.main // Light blue
    backgroundColor: `${theme.palette.grey[300]}`
  },
  make: {
    // backgroundColor: '#CC90C3' // Light yellow
    backgroundColor: `${theme.palette.grey[300]}`
  },
  primaryMainColor: {
    color: theme.palette.primary.main
  },
  primaryMainColorDisabled: {
    color: theme.palette.grey[300]
  },
  buyGoldMenuItem: {
    backgroundColor: theme.palette.secondary.main // Light green
  },
  buyCoinMenuItem: {
    backgroundColor: theme.palette.secondary.main // Light green
  },
  innerDiv: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: '0.5rem',
    borderRadius: '1.5rem'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  lastItem: {
    paddingBottom: '5rem!important'
  },
  lastItemSmall: {
    paddingBottom: '10rem!important'
  },
  image: {
    width: 50,
    height: 50
  }
}))

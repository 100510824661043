import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useNavigate, useParams } from 'react-router-dom'
import ringURL from '../../assets/ring.png'
import { ProductAlak } from '../../types/productMock'
import Header from '../../components/Header'
import { Button, ButtonBase, IconButton, Typography } from '@mui/material'
import { addCommaToPrice } from '../../helpers/numbers'
import InfoIcon from '@mui/icons-material/Info'
import StraightenIcon from '@mui/icons-material/Straighten'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { useStyles } from './styles/index'

function BuyGoldProductDetail() {
  const classes = useStyles()
  const { subpage } = useParams()
  const navigate = useNavigate()

  //TODO: this list should be fetched from an API with subpage parameter
  const product: ProductAlak = {
    title: 'انگشتر فرد سه نگین',
    code: 'AR517',
    price: 15456000,
    imageURL: ringURL,
    weight: 2.5,
    category: 'ring',
    description: 'انگشتر فرد سه نگین  خیلی خوشگله',
    isFavorite: false,
    jewelerFeePercent: 26,
    isBestSeller: true,
    avaialability: 10,
    size: 54,
    brightness: 'براق',
    isPayableWithSnappPay: true
  }

  return (
    <div className={classes.root}>
      <Header title={product.title} existIcons />
      {/* TODO: implement header and sort/filter to the tope of menuItems */}
      <div className={classes.title}>
        <div>
          <Typography variant="body1">{product.title}</Typography>
          <Typography variant="body2" className={classes.code}>
            {'کد: ' + product.code}
          </Typography>
        </div>
        {product.avaialability > 0 ? (
          <Typography variant="body1" className={classes.available}>
            {'موجود'}
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.unavailable}>
            {'ناموجودی'}
          </Typography>
        )}
      </div>
      <div className={classes.divider} />

      <div className={classes.featuresContainer}>
        <Typography variant="h5">{'مشخصات: '}</Typography>
        <div className={classes.features}>
          <div className={classes.feature}>
            <Typography variant="body1" className={classes.featureTitle}>
              {'وزن'}
            </Typography>
            <Typography variant="body1">{product.weight + ' گرم'}</Typography>
          </div>
          <div className={classes.feature}>
            <Typography variant="body1" className={classes.featureTitle}>
              {'اجرت'}
            </Typography>
            <Typography variant="body1">
              {product.jewelerFeePercent + ' ٪'}
            </Typography>
          </div>
          <div className={classes.feature}>
            <Typography variant="body1" className={classes.featureTitle}>
              {'درخشندگی'}
            </Typography>
            <Typography variant="body1">{product.brightness}</Typography>
          </div>
          <div className={classes.feature}>
            <Typography variant="body1" className={classes.featureTitle}>
              {'سایز'}
            </Typography>
            <Typography variant="body1">{product.size}</Typography>
          </div>
          <div className={classes.feature}>
            <Typography variant="body1" className={classes.featureTitle}>
              {'قیمت'}
            </Typography>
            <Typography variant="body1">{`${addCommaToPrice(
              product.price
            )}`}</Typography>
          </div>
        </div>
        <Button variant="contained" fullWidth className={classes.addToCartBtn}>
          {'افزودن به سبد خرید'}
        </Button>
      </div>
      <div className={classes.divider} />
      <div className={classes.itemFavcontainer}>
        <div className={classes.additionalItems}>
          <ButtonBase>
            <InfoIcon className={classes.ItemsIcon} />
            <Typography variant="body2" className={classes.ItemText}>
              {'راهنمای خرید'}
            </Typography>
          </ButtonBase>
          <ButtonBase>
            <StraightenIcon className={classes.ItemsIcon} />
            <Typography variant="body2" className={classes.ItemText}>
              {'راهنمای سایز'}
            </Typography>
          </ButtonBase>
          <ButtonBase>
            <LocalShippingIcon className={classes.ItemsIcon} />
            <Typography variant="body2" className={classes.ItemText}>
              {'شرایط ارسال محصول'}
            </Typography>
          </ButtonBase>
          <ButtonBase>
            <RefreshIcon className={classes.ItemsIcon} />
            <Typography variant="body2" className={classes.ItemText}>
              {'شرایط بازگشت و تعویض محصول'}
            </Typography>
          </ButtonBase>
        </div>
        <div className={classes.favorites}>
          <IconButton className={classes.favoritesIconBtn} aria-label="menu">
            <FavoriteBorderOutlinedIcon className={classes.favoritesIcon} />
          </IconButton>
          <Typography variant="body1">{'علاقه مندی‌ها'}</Typography>
        </div>
      </div>

      <div className={classes.imgContainer}>
        <img
          className={classes.image}
          src={product.imageURL}
          alt={product.title}
        />
      </div>
    </div>
  )
}
// TODO: this page needs pagination

export default BuyGoldProductDetail

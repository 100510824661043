import { Modal, Paper, Slide } from '@mui/material'
import { useEffect, useState } from 'react'
import logo from '../../assets/logo.svg'
import ProgressComponent from './ProgressComponent'
import useStyles from './styles/index.styles'

type LoadingScreenProps = {
  open: boolean
  numberOfCircles: number
}

export default function LoadingScreen({
  open,
  numberOfCircles
}: LoadingScreenProps) {
  const classes = useStyles()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setIsOpenModal(true)
    } else {
      setIsOpenModal(false)
    }
  }, [open])

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  return (
    <div className={classes.modalContainer}>
      <Modal dir={'rtl'} open={isOpenModal} onClose={onCloseModal}>
        <Slide in={isOpenModal} direction="down">
          <Paper className={classes.paper}>
            <img src={logo} alt="loading" className={classes.profileImage} />
            <ProgressComponent numberOfCircles={numberOfCircles} />
          </Paper>
        </Slide>
      </Modal>
    </div>
  )
}

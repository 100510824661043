import createTheme from '@mui/material/styles/createTheme'

export const theme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#1C60FF',
      light: '#CBD7FF',
      dark: '#506495',
      contrastText: '#fff'
    },
    secondary: {
      main: '#D4AE66'
    },
    success: {
      main: '#40C86F',
      light: '#B7EBB0',
      dark: '#369A6B'
    },
    error: {
      main: '#DF2C2C',
      light: '#FFBBBB',
      dark: '#A2302C'
    },
    info: {
      main: '#0288d1',
      dark: '#013974',
      light:
        'linear-gradient(90deg, rgba(1, 57, 116, 0.55) 0%, rgba(1, 57, 116, 0.2) 100%)'
    },
    background: {
      default: '#FFF',
      paper: '#EDF1FF'
    },
    text: {
      primary: '#000',
      disabled: '#AEB9BE'
    }
  },
  typography: {
    fontFamily: 'IranSans',
    h1: {
      fontWeight: 700,
      fontSize: 36
    },
    h2: {
      fontWeight: 700,
      fontSize: 24
    },
    h3: {
      fontWeight: 700,
      fontSize: 20
    },
    h4: {
      fontWeight: 600,
      fontSize: 16
    },
    h5: {
      fontWeight: 600,
      fontSize: 14
    },
    h6: {
      fontWeight: 600,
      fontSize: 12
    },
    body1: {
      fontWeight: 500,
      fontSize: 14
    },
    body2: {
      fontWeight: 500,
      fontSize: 12
    },
    button: {
      fontWeight: 500,
      fontSize: 16
    }
  }
})

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1, // Makes the content area grow to fill available space
    width: '100%'
  },
  topTabsContainer: {
    marginTop: '0.5rem'
  },
  topTabs: {
    background: theme.palette.grey[100],
    borderRadius: 12,
    '& .MuiTabs-flexContainer': {
      display: 'grid!important',
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    '& .Mui-selected': {
      background: theme.palette.info.dark,
      color: `${theme.palette.primary.contrastText}!important`,
      borderRadius: 12,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '& .MuiTabs-indicator': {
      width: '0px!important'
    }
  },
  topTabText: {
    fontSize: `0.625rem!important`
  },
  chartSection: {
    display: 'grid!important',
    gridTemplateColumns: '6fr 1.5fr',
    alignItems: 'center',
    marginTop: '0.5rem'
  },
  chartSectionTexts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  title1: {
    fontSize: '0.7rem!important',
    fontWeight: '600!important'
  },
  title2: {
    fontSize: '0.4rem!important'
  },
  title3: {
    fontSize: '0.4rem!important',
    color: theme.palette.grey[600]
  },
  bottomTabsContainer: {
    marginTop: '0.5rem'
  },
  bottomTabs: {
    background: theme.palette.info.light,
    borderRadius: 16,
    '& .MuiTabs-flexContainer': {
      display: 'grid!important',
      gridTemplateColumns: '1fr 1fr'
    },
    '& .Mui-selected': {
      background: theme.palette.info.dark,
      color: `${theme.palette.primary.contrastText}!important`,
      borderRadius: 16,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '& .MuiTabs-indicator': {
      width: '0px!important'
    }
  },
  bottomTabText: {
    fontSize: `1rem!important`
  },
  cartItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem'
  },
  cartItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  }
}))

import React from 'react'
import Footer from '../Footer'
import { footerType } from '../../types/footer'
import { useStyles } from './styles'

type Props = {
  component: React.ReactNode
  step?: footerType
}

const WithFooter = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.component}
      <Footer step={props.step} />
    </div>
  )
}

export default WithFooter

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  connector: {
    height: 2,
    position: 'absolute',
    top: 12,
    background: theme.palette.secondary.main,
    width: '3.5rem',
    right: -27,
    [theme.breakpoints.down('sm')]: {
      width: '2.5rem',
      right: -20
    },
    [theme.breakpoints.up('lg')]: {
      width: '4.5rem',
      right: -35
    }
  },
  connectorSuccess: {
    background: theme.palette.success.main
  },
  incompleteConnector: {
    background: theme.palette.grey[300]
  }
}))

import { Button, Typography } from '@mui/material'
import { addCommaToPrice } from '../../helpers/numbers'
import { useStyles } from './styles/index'

export type CoinCardItem = {
  imageURL: string
  title: string
  price: number
  code: string
  onClick: () => void
}

interface CoinCardProps {
  data: CoinCardItem
}

export const CoinCard: React.FC<CoinCardProps> = ({ data }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.imgContainer}>
          <img src={data.imageURL} alt={data.title} className={classes.img} />
        </div>
        <Typography variant="h4">{data.title}</Typography>
        <Typography variant="h6" className={classes.price}>{`${addCommaToPrice(
          data.price
        )} تومان`}</Typography>
        <div>
          <Button
            onClick={() => {
              data.onClick()
            }}
            variant="contained"
            fullWidth
            className={classes.buyBtn}>{`خرید`}</Button>
        </div>
      </div>
    </div>
  )
}

export default CoinCard

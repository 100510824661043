import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.main,
    padding: '0.5rem 0.5rem',
    borderRadius: '1rem!important',
    width: '19.37rem'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem'
  },
  icon: {
    color: theme.palette.warning.light
  },
  textColor: {
    color: theme.palette.primary.contrastText
  },
  textColorSucces: {
    color: theme.palette.success.main
  },
  midSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  midSectionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '0.5rem'
  },
  child: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  progress: {
    width: '100%',
    marginTop: '1.5rem',
    height: '0.5rem'
  },
  succesTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1.5rem',
    height: '0.5rem'
  },
  button: {
    borderRadius: '1rem!important',
    background: `${theme.palette.error.main}!important`,
    marginTop: '1rem!important'
  }
}))

import Header from '../../components/Header'
import TextAreaInput from '../../components/TextAreaInput'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { GeneralResponse } from '../../types/general'
import { useState } from 'react'
import { ChangePasswordInput } from '../../types/changePass'
import { Button } from '@mui/material'
import { useStyles } from './styles/index'

function ChangePassword() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [inputs, setInputs] = useState<ChangePasswordInput>({
    current_password: '',
    new_password: '',
    new_confirm_password: ''
  })

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/
  const isValidPassword = (password: string): boolean => {
    return passwordRegex.test(password)
  }

  const handleClickInput = () => {
    setIsVisible(!isVisible)
  }

  const onClickLoginButton = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<GeneralResponse>(
      '/api/change-password',
      {
        method: 'POST',
        body: inputs
      }
    )
    setLoading(false)

    if (data?.status) {
      toast.success(data?.message)
    } else {
      toast.error(error || 'Login failed. Please try again.')
    }
  }

  return (
    <div className={classes.root}>
      <Header title={'مدیریت کلمه عبور'} />
      <div className={classes.row}>
        <TextAreaInput
          title={'رمز فعلی'}
          placeholder={'رمز فعلی خود را وارد نمایید'}
          type={'password'}
          value={inputs.current_password}
          onChange={(val) => {
            setInputs({
              ...inputs,
              current_password: val
            })
          }}
        />
      </div>
      <div className={classes.row}>
        <TextAreaInput
          title={'رمز جدید'}
          placeholder={'رمز جدید خود را وارد نمایید'}
          icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClick={handleClickInput}
          type={isVisible ? 'text' : 'password'}
          value={inputs.new_password}
          helperText={
            isValidPassword(inputs.new_password)
              ? ''
              : 'کلمه عبور باید حداقل شامل هشت حرف باشد که یک حرف بزرگ و یه کاراکتر ویژه هم داشته باشد.'
          }
          onChange={(val) => {
            setInputs({
              ...inputs,
              new_password: val
            })
          }}
        />
      </div>
      <div className={classes.row}>
        <TextAreaInput
          title={'تایید رمز جدید'}
          placeholder={'مجددا رمز جدید خود را وارد نمایید'}
          icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClick={handleClickInput}
          type={isVisible ? 'text' : 'password'}
          value={inputs.new_confirm_password}
          helperText={
            !inputs.new_confirm_password
              ? ''
              : inputs.new_confirm_password === inputs.new_password
              ? ''
              : 'رمز عبور یکسان نیست'
          }
          onChange={(val) => {
            setInputs({
              ...inputs,
              new_confirm_password: val
            })
          }}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={
            !inputs.current_password ||
            !inputs.new_password ||
            !inputs.new_confirm_password ||
            loading
          }
          onClick={onClickLoginButton}>
          {'تایید'}
        </Button>
      </div>
    </div>
  )
}

export default ChangePassword

import { Typography } from '@mui/material'
import Header from '../../components/Header'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { useNavigate } from 'react-router-dom'
import { ProfileItem } from '../../types/profile'
import { useStyles } from './styles/index'

function Setting() {
  const classes = useStyles()
  const navigate = useNavigate()
  const items: ProfileItem[] = [
    {
      title: 'مدیریت اطلاع رسانی',
      icon: <NotificationsNoneOutlinedIcon className={classes.icon} />,
      path: 'notification'
    },
    {
      title: 'مدیریت کلمه عبور',
      icon: <VpnKeyOutlinedIcon className={classes.icon} />,
      path: 'change-password'
    },
    {
      title: 'حذف حساب کاربری',
      icon: <DeleteForeverOutlinedIcon className={classes.icon} />,
      path: '/'
    }
  ]

  return (
    <div className={classes.root}>
      <Header title={'تنظیمات'} />
      {items.map((item) => (
        <div
          className={classes.row}
          onClick={() => {
            navigate(item.path)
          }}>
          <Typography variant="button">{item.title}</Typography>
          <div className={classes.iconContainer}>{item.icon}</div>
        </div>
      ))}
    </div>
  )
}

export default Setting

import {
  InputAdornment,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles/index'

type Props = {
  title: string
  placeholder?: string
  icon?: ReactNode
  onClick?: () => void
  type: React.HTMLInputTypeAttribute
  forgetPass?: boolean
  value: string | number
  helperText?: string
  onChange?: (v: string) => void
  disabled?: boolean
}

export default function TextAreaInput({
  title,
  placeholder,
  icon,
  onClick,
  type,
  forgetPass,
  value,
  helperText,
  onChange,
  disabled
}: Props) {
  const classes = useStyles()
  const navigate = useNavigate()

  const onClickForgotPassword = () => {
    navigate('/forgot-password')
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <TextField
        placeholder={placeholder}
        inputProps={{
          className: classes.input
        }}
        InputProps={{
          endAdornment: icon && (
            <InputAdornment
              position="end"
              className={classes.icon}
              onClick={onClick}>
              {icon}
            </InputAdornment>
          )
        }}
        type={type}
        value={value}
        onChange={(e) => onChange?.(e.currentTarget.value)}
        disabled={disabled}
      />
      <FormHelperText
        classes={{
          root: classes.helperText
        }}>
        {helperText}
      </FormHelperText>
      {forgetPass && (
        <div className={classes.forgetPass} onClick={onClickForgotPassword}>
          <Typography variant="overline">{'فراموشی رمز عبور'}</Typography>
        </div>
      )}
    </div>
  )
}

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 350,
    padding: '2rem 1rem 1.5rem 1rem',
    marginTop: '15vh',
    zIndex: 1,
    outline: 'none',
    position: 'relative',
    borderRadius: 16,
    background: `${theme.palette.primary.contrastText} !important`,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginTop: '10vh',
      marginBottom: '10vh'
    }
  },
  profileImage: {
    width: '60%',
    height: '60%'
  }
}))

export default useStyles

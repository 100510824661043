import React, { useEffect, useState } from 'react'
import useStyles from './styles/ProgressComponent.styles'
import { useTheme } from '@mui/material'

interface Props {
  numberOfCircles: number
}

const ProgressComponent: React.FC<Props> = ({ numberOfCircles }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [circleIndex, setCircleIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCircleIndex((prevIndex) => (prevIndex + 1) % 4)
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.spinner}>
        {Array(numberOfCircles)
          .fill(' ')
          .map((_, index) => (
            <div
              style={{ backgroundColor: `${theme.palette.primary.main}` }}
              key={index}
              className={`${classes.circle} ${
                index === circleIndex ? classes.activeCircle : ''
              }`}
            />
          ))}
      </div>
    </div>
  )
}

export default ProgressComponent

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  spinner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 5,
    margin: 5,
    opacity: 0.3,
    transition: 'opacity 0.3s ease-in-out'
  },
  activeCircle: {
    opacity: 1
  }
})

export default useStyles

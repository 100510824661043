import React, { useEffect, useState } from 'react'
import { Box, Typography, Avatar, Button, Divider } from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { ProfileResponse, UserInfoInputs } from '../../types/userInfo'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import logo from '../../assets/logo.svg'
import { WalletData, WalletResponse } from '../../types/wallet'
import Header from '../../components/Header'
import { addCommaToPrice } from '../../helpers/numbers'

const WalletScreen: React.FC = () => {
  const [data, setData] = useState<UserInfoInputs>({
    name: '',
    lastName: '',
    birthDate: null,
    nationalCode: '',
    phoneNumber: ''
  })
  const [walletData, setWalletData] = useState<WalletData>({
    id: 0,
    user_id: 0,
    money_balance: '0',
    gold_balance: '0'
  })

  const GetData = async () => {
    const { data, error } = await apiRequest<ProfileResponse>(
      '/api/get-profile',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData({
        name: data.data.name,
        lastName: data.data.lastName,
        birthDate: data.data.birthDate,
        nationalCode: data.data.nationalCode,
        phoneNumber: data.data.mobile
      })
    } else {
      toast.error(error || 'Get profile data failed. Please try again.')
    }
  }

  const GetWalletData = async () => {
    const { data, error } = await apiRequest<WalletResponse>('/api/wallet', {
      method: 'GET'
    })

    if (data?.status) {
      setWalletData({
        id: data.data.id,
        user_id: data.data.user_id,
        money_balance: data.data.money_balance,
        gold_balance: data.data.gold_balance
      })
    } else {
      toast.error(error || 'Get wallet data failed. Please try again.')
    }
  }

  useEffect(() => {
    GetData()
    GetWalletData()
  }, [])

  return (
    <>
      <Header title={'کیف پول'} />
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          bgcolor: 'background.paper',
          borderRadius: 4,
          overflow: 'hidden',
          boxShadow: 3,
          marginTop: 2
        }}>
        {/* User Info */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            textAlign: 'center'
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}>
            <Avatar src={logo} sx={{ width: 64, height: 64 }} />
            <Typography variant="h6" sx={{ ml: 2 }}>
              {data.name + data.lastName}
            </Typography>
          </Box>
          <AccountBalanceWalletIcon
            sx={{ fontSize: 48, color: '#1a73e8', mr: 2 }}
          />
        </Box>
        <Divider />
        {/* Gold Balance Section */}
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">
            موجودی طلا
          </Typography>
          <Typography variant="h5">{walletData.gold_balance}</Typography>
          <Typography variant="body2" color="textSecondary">
            {'گرم'}
          </Typography>
        </Box>
        <Divider />
        {/* Rial Balance Section */}
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">
            موجودی تومان
          </Typography>
          <Typography variant="h5">
            {addCommaToPrice(walletData.money_balance)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {'تومان'}
          </Typography>
        </Box>
        <Divider />
        {/* Reload Button */}
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {}}
            disabled
            sx={{ width: '100%', py: 1.5 }}>
            شارژ کیف پول
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default WalletScreen

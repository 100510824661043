import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.light,
    padding: '0.5rem 1.5rem',
    borderRadius: 16,
    width: '20rem',
    marginBottom: '1rem'
  },
  upSection: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr'
  },
  userAvatar: {
    width: '5rem',
    height: '5rem'
  },
  productName: {
    paddingRight: '1rem'
  },
  icon: {
    backgroundColor: `${theme.palette.primary.contrastText}!important`,
    color: `${theme.palette.primary.main}!important`,
    fontSize: '2rem!important',
    borderRadius: '50%',
    padding: '0.25rem'
  },
  buttons: {
    paddingTop: '1rem',
    display: 'flex'
  },
  pey: {
    borderRadius: '1rem!important',
    background: `${theme.palette.primary.main}!important`,
    padding: '0.4rem 1rem !important'
  },
  btn: {
    borderRadius: '1rem!important',
    background: `${theme.palette.primary.contrastText}!important`,
    padding: '0.4rem 1rem !important',
    color: `${theme.palette.primary.main}!important`
  }
}))

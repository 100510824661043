import Header from '../../components/Header'
import { useStyles } from './styles/index.styles'

export default function Rules() {
  const classes = useStyles()

  return (
    <div>
      <Header title={'قوانین و امنیت'} />
      <div className={classes.root}>
        قوانین و مقررات؛
        <br />
        تعهدنامه فعالیت در پلتفرم زُزان
        <br />
        🔹کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه نموده
        و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.
        <br />
        🔹کاربران سایت می‌پذیرند که کلیه‌ی فعالیت‌های آن‌ها در ززان در چارچوب
        قوانین جمهوری اسلامی بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام
        نخواهند داد.
        <br />
        <b>
          🔹بعد از تایید خرید یا فروش توسط کاربر ، طلا یا سکه مورد انتخاب ثبت
          نهایی شده و برای کنسل کردن شامل ضرر و زیان میشود و مسئولیت آن با کاربر
          میباشد و حق هیچ گونه اعتراضی را ندارد
        </b>
        <br />
        🔹احراز هویت برای استفاده از خدمات زُزان ضروری بوده و کاربران موظفند
        اطلاعات صحیح خود را بارگذاری نمایند. بدیهی است در صورت وجود هرگونه تخلف
        در احراز هویت، مسئولیت به عهده‌ی فرد متخلف بوده و مجموعه ززان حق توقف
        ارائه خدمات به کاربر مذبور و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد
        داشت. مجموعه ززان خود را ملزم به حفظ اطلاعات شخصی کاربران خود می‌داند.
        <br />
        🔹کاربران مجموعه زُزان تعهد می دهند که از خدمات سایت ززان تنها برای خود
        استفاده نموده و مسئولیت استفاده از خدمات ززان برای فرد غیر که فرآیند
        احراز هویت را طی نکرده باشد به عهده کاربر خواهد بود. شماره حساب اعلام
        شده به سایت و همچنین آدرس کیف پول ها جهت برداشت طلا نیز می بایست متعلق
        به کاربر بوده و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر
        نیستند.
        <br />
        🔹کاربران ززان اذعان می‌دارند که از ریسک‌های مربوط به سرمایه‌گذاری در
        طلا مطلع بوده و با علم به این موضوع اقدام به سرمایه‌گذاری و تبادل
        می‌نمایند.
        <br />
        🔹پلتفرم زُزان به عنوان بازار آنلاین تبادل طلا و سکه، هیچ گونه مسئولیتی
        در قبال نحوه‌ی معاملات کاربران و سود و زیان حاصل از آن ندارد.
        <br />
        🔹هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله رمز عبور
        کاربر، به عهده‌ی شخص کاربر بوده و مجموعه ززان هیچ مسئولیتی به عهده
        نخواهد داشت. اکیداً توصیه می شود از رمز عبور پیچیده و امن و کد شناسایی
        دوعاملی استفاده شود.
        <br />
        🔹مجموعه زُزان این اطمینان را می‌دهد که دارایی‌های کاربران را نزد خود به
        امانت و به بهترین شکل و با بالاترین استانداردهای امنیتی ممکن، حفظ نماید.
        در صورت بروز هرگونه مشکل امنیتی، مجموعه زُزان متعهد به جبران خسارت خواهد
        بود.
        <br />
        🔹درخواست برداشت ریال از حساب کاربری در سریع‌ترین زمان ممکن پس از ثبت،
        بررسی خواهد شد. زمان واریز پول به حساب کاربران بر اساس محدودیت‌های
        انتقال وجه بین بانکی، متفاوت خواهد بود. برای اطلاعات بیش‌تر، به قوانین
        انتقال وجه بین بانکی (پایا ، ساتنا) مراجعه فرمایید
        <br />
        🔹کاربر می‌پذیرد که به جز در موارد مورد تعهد زُزان، حق هیچ گونه داعیه،
        طلب و شکایت از سایت زُزان، مدیران، کارمندان و افراد مرتبط با این سایت را
        نخواهد داشت.
        <br />
        🔹اگر زُزان تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا طلا را به حساب
        کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار
        است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و قضائی و دریافت
        اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی
        اقدام نماید و تشخیص مجموعه زُزان نسبت به وقوع اشتباه یا پرداخت بدون حق و
        لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را
        در خصوص نحوه عملکرد زُزان از هر جهت از خود ساقط می نماید.
        <br />
        🔹در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، زُزان حق دارد
        مستقلاً آن معامله را ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان
        عودت دهد. بدیهی است که در صورتی که اشکال از سمت زُزان باشد، موظف خواهد
        بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و
        ادعایی را در خصوص نحوه عملکرد زُزان از هر جهت از خود ساقط می‌نماید.
        <br />
        🔹در صورت هرگونه سوء استفاده از پلتفرم زُزان در فعالیت‌های خلاف قانون،
        تمامی مسئولیت آن به عهده کاربر بوده و زُزان هیچ گونه مسئولیتی در قبال
        هرگونه سوء استفاده از سیستم معرفی خود به هر نحو ندارد. همچنین زُزان حق
        باطل کردن کد معرف و بستن حساب در صورت مشاهده هرگونه سوء استفاده بدون
        نیاز به دستور قضایی را خواهد داشت.
        <br />
        🔹در صورت فوت یا حجر کاربر، زُزان این حق را خواهد داشت به محض اطلاع از
        آن نسبت به واریز وجوه ریالی کاربر نزد مجموعه، به حساب اعلام شده ایشان در
        زمان ثبت نام اقدام نموده و در خصوص دارائی طلایی نیز این حق و اختیار به
        مجموعه واگذار گردید که نسبت به تبدیل آن به ریال معادل قیمت روز و واریز
        آن به حساب فوق اقدام نماید و این حق و امتیاز توسط کاربر به مجموعه صلح و
        اعطا گردید. لازم به ذکر است ملاک زمانی محاسبه قیمت فروش دارائی طلایی
        کاربر، براساس ارائه اسناد مثبته توسط ذینفع و تایید اصالت آن توسط شعبه
        زُزان است. کاربر با علم و اطلاع از مقررات این بند نسبت به افتتاح حساب
        کاربری در زُزان اقدام نموده و کاربر، اشخاص ثالث، ورثه یا قائم مقام
        قانونی ایشان حق اعتراض و یا ادعایی در آینده در این خصوص را نخواهند داشت.
        <br />
        🔹سرمایه گذاری در طلا به صورت بلند مدت همیشه سودمند بوده و خرید و فروش
        در کوتاه مدت ممکن است شامل ضرر و زیان شود و مسئولیت آن به عهده کاربر
        میباشد.
        <br />
        🔹دریافت طلا و سکه فقط به صورت حضوری میباشد 🔹حداقل میزان دریافت طلا
        آبشده ۳۰ گرم میباشد، کاربران گرامی میتونن در قالب شمش از ۱ گرم تا ۳۰ گرم
        دریافت کنن ولی شامل اجرت میشه و اگه بخوان شامل اجرت نشه باید موجودی
        طلایی کیف پول خودشونو به ۳۰ گرم برسونن.
        <br />
        🔹هنگام دریافت طلا و سکه ، همراه داشتن کارت ملی الزامی میباشد.
        <br />
        🔹ثبت نام با شماره تماس: با شماره تماس خود در زُزان ثبت‌نام کنید و با
        وارد کردن کد تایید ارسالی ثبت‌نام شما انجام می‌شود. در وارد نمودن شماره
        موبایل دقت نمایید زیرا یکی از راه‌های ارتباطی ما با شما در هنگام بروز
        مشکل همین خواهد بود.
        <br />
        🔹ثبت اطلاعات هویتی: با وارد کردن نام و نام خانوادگی، کدملی و تاریخ تولد
        و بارگذاری یک مدرک هویتی سطح آبی به شما اعطا میشود و پس از آن میتوانید،
        در زُزان شروع به معامله کنید.
        <br />
        🔹تأیید تلفن ثابت و آدرس: به منظور ارتقاء به سطح نقره ای و طلایی کاربری،
        وارد کردن اطلاعات سکونتی الزامیست.
        <br />
        🔹تغییر در قوانین: هرگونه تغییر در قوانین، شروط ارائه خدمات، کارمزدها و
        مقررات مشابه، از طریق پلتفرم زُزان به کاربران اطلاع‌رسانی خواهد شد.
      </div>
    </div>
  )
}

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 500,
    width: '90%',
    height: '3rem',
    padding: '0rem 1rem',
    background: theme.palette.primary.main,
    borderRadius: '1rem',
    position: 'fixed',
    bottom: '1rem'
  },
  icon: {
    color: theme.palette.primary.contrastText,
    fontSize: 28
  },
  iconSelected: {
    color: theme.palette.text.primary
  }
}))

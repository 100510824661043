import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { LocalizedRoute } from './localizedRoutes'
import Welcome from '../screens/Welcome'
import Login from '../screens/Login'
import SignUp from '../screens/SignUp'
import HomePage from '../screens/HomePage'
import UserInfo from '../screens/UserInfo'
import VerificationCode from '../screens/VerificationCode'
import MeltedGold from '../screens/MeltedGold'
// import MeltedGoldBuy from '../screens/MeltedGoldBuy'
// import MeltedGoldSell from '../screens/MeltedGoldSell'
import Profile from '../screens/Profile'
import BuyGoldSelectSubPage from '../screens/BuyGoldSelectSubPage'
import BuyGoldSubPage from '../screens/BuyGoldSubPage'
import BuyGoldProducts from '../screens/BuyGoldProducts'
import ForgotPassword from '../screens/ForgotPassword'
import BuyGoldProductDetail from '../screens/BuyGoldProductDetails'
import Setting from '../screens/Setting'
import NotificationSetting from '../screens/NotificationSetting'
import ChangePassword from '../screens/ChangePassword'
import Favorites from '../screens/Favorites'
import BuyCoinPage from '../screens/BuyCoin'
import BuyGoldGallaryPage from '../screens/BuyGoldGallary'
import BuyCoinList from '../screens/BuyCoinList'
import WithFooter from '../components/WithFooter'
import RepairGold from '../screens/RepairGold'
import CreateGold from '../screens/CreateGold'
import ChatRoom from '../screens/ChatRoom'
import Notifications from '../screens/Notifications'
import VerificationCodeForPassword from '../screens/VerificationCodeForPassword'
import Orders from '../screens/Orders'
import BankCart from '../screens/BankCart'
import WalletScreen from '../screens/wallet'
import Rules from '../screens/rules'

export default function PanelRoutes() {
  const { pathname } = useLocation()

  // if (pathname.includes('welcome') || !pathname || pathname === '/') {
  if (!pathname || pathname === '/') {
    return (
      <Routes>
        <Route path="*" element={<Navigate to={`/home`} replace />} />
      </Routes>
    )
  }

  return (
    <>
      {/* Public Routes */}
      <LocalizedRoute path="/welcome" element={<Welcome />} />
      <LocalizedRoute path="/login" element={<Login />} />
      <LocalizedRoute path="/forgot-password" element={<ForgotPassword />} />
      <LocalizedRoute path="/verify-code" element={<VerificationCode />} />
      <LocalizedRoute
        path="/verify-code-password"
        element={<VerificationCodeForPassword />}
      />
      <LocalizedRoute path="/sign-up" element={<SignUp />} />
      {/* Authenticated Routes */}
      <LocalizedRoute
        path="/user-info"
        element={<WithFooter component={<UserInfo />} />}
      />
      <LocalizedRoute
        path="/home"
        element={<WithFooter component={<HomePage />} step="home" />}
      />
      {/* Gold-Related Routes */}
      <LocalizedRoute
        path="/melted-gold"
        element={<WithFooter component={<MeltedGold />} />}
      />
      {/* <LocalizedRoute
        path="/melted-gold/sell"
        element={<WithFooter component={<MeltedGoldSell />} />}
      /> */}
      {/* <LocalizedRoute
        path="/melted-gold/buy"
        element={<WithFooter component={<MeltedGoldBuy />} />}
      /> */}
      <LocalizedRoute
        path="/profile"
        element={<WithFooter component={<Profile />} step="profile" />}
      />
      <LocalizedRoute
        path="/notification"
        element={<WithFooter component={<Notifications />} />}
      />
      <LocalizedRoute
        path="/profile/setting"
        element={<WithFooter component={<Setting />} />}
      />
      <LocalizedRoute
        path="/profile/setting/notification"
        element={<WithFooter component={<NotificationSetting />} />}
      />
      <LocalizedRoute
        path="/profile/setting/change-password"
        element={<WithFooter component={<ChangePassword />} />}
      />
      <LocalizedRoute
        path="/favorite"
        element={<WithFooter component={<Favorites />} />}
      />
      <LocalizedRoute
        path="/bank-cart"
        element={<WithFooter component={<BankCart />} />}
      />
      <LocalizedRoute
        path="/wallet"
        element={<WithFooter component={<WalletScreen />} />}
      />
      <LocalizedRoute
        path="/rules"
        element={<WithFooter component={<Rules />} />}
      />
      <LocalizedRoute
        path="/orders"
        element={<WithFooter component={<Orders />} step="orders" />}
      />
      <LocalizedRoute
        path="/buy-gold"
        element={<WithFooter component={<BuyGoldGallaryPage />} />}
      />
      <LocalizedRoute
        path="/buy-gold/:gallary"
        element={<WithFooter component={<BuyGoldSelectSubPage />} />}
      />
      <LocalizedRoute
        path="/buy-gold/:gallary/:subpage"
        element={<WithFooter component={<BuyGoldSubPage />} />}
      />
      <LocalizedRoute
        path="/buy-gold/:gallary/:subpage/products/:productType"
        element={<WithFooter component={<BuyGoldProducts />} />}
      />
      <LocalizedRoute
        path="/buy-gold/:gallary/:subpage/products/:productType/:productCode"
        element={<WithFooter component={<BuyGoldProductDetail />} />}
      />
      <LocalizedRoute
        path="/repair-gold"
        element={<WithFooter component={<RepairGold />} />}
      />
      <LocalizedRoute
        path="/create-gold"
        element={<WithFooter component={<CreateGold />} />}
      />
      <LocalizedRoute
        path="/buy-coin"
        element={<WithFooter component={<BuyCoinPage />} />}
      />
      <LocalizedRoute
        path="/buy-coin/:subpage/products"
        element={<WithFooter component={<BuyCoinList />} />}
      />
      <LocalizedRoute
        path="/chat-room"
        element={<WithFooter component={<ChatRoom />} step="chat" />}
      />
    </>
  )
}

import { Typography } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Notification } from '../../types/notificationItem'
import { getFormattedDate } from '../../helpers/date'
import { useStyles } from './styles/index'

type Props = {
  data: Notification
}

export default function NotificationItem({ data }: Props) {
  const classes = useStyles()
  const dateInfo = getFormattedDate(data.created_at)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {dateInfo === 'امروز' ? (
          <Typography variant="body2" className={classes.markAll}>
            {'خواندن همه'}
          </Typography>
        ) : (
          <div />
        )}
        <Typography variant="h4" className={classes.dateInfo}>
          {dateInfo}
        </Typography>
      </div>
      {/* {data.items.map((item: NotificationDataItems) => (
        <div
          className={[
            classes.mainSection,
            !item.unread && classes.mainSectionRead
          ].join(' ')}>
          <Typography>{item.past}</Typography>
          <div className={classes.texts}>
            <Typography variant="h3">{item.title}</Typography>
            <Typography variant="h6">{item.subTitle}</Typography>
          </div>
          <EventNoteIcon />
        </div>
      ))} */}
      <div
        className={[
          classes.mainSection,
          data.read_at && classes.mainSectionRead
        ].join(' ')}>
        {/* <Typography>{'item.past'}</Typography> */}
        <div className={classes.texts}>
          <Typography variant="h3">{data.data.title}</Typography>
          <Typography variant="h6">{data.data.description}</Typography>
        </div>
        <EventNoteIcon />
      </div>
    </div>
  )
}

import { IconButton, Typography } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsIcon from '@mui/icons-material/Settings'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import { useNavigate } from 'react-router-dom'
import { apiRequest, getToken } from '../../helpers/request'
import { useStyles } from './styles/Header'
import { ProfileResponse, UserInfoInputs } from '../../types/userInfo'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

interface HomeHeaderProps {
  imageUrl: string
}

export const HomeHeader: React.FC<HomeHeaderProps> = ({ imageUrl }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [data, setData] = useState<UserInfoInputs>({
    name: '',
    lastName: '',
    birthDate: null,
    nationalCode: '',
    phoneNumber: ''
  })
  const token = getToken()

  const onClickOnNotification = () => {
    navigate('/notification')
  }

  const onClickOnSetting = () => {
    navigate('/profile/setting')
  }

  const onClickOnUserSection = () => {
    token ? navigate('/profile') : navigate('/welcome')
  }

  const onClickOnFavorite = () => {
    navigate('/favorite')
  }

  const GetData = async () => {
    const { data, error } = await apiRequest<ProfileResponse>(
      '/api/get-profile',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData({
        name: data.data.name,
        lastName: data.data.lastName,
        birthDate: data.data.birthDate,
        nationalCode: data.data.nationalCode,
        phoneNumber: data.data.mobile
      })
    } else {
      toast.error(error || 'Get profile data failed. Please try again.')
    }
  }

  useEffect(() => {
    token && GetData()
  }, [token])

  return (
    <div className={classes.root}>
      <div className={classes.iconTopRightContainer}>
        <IconButton className={classes.iconBtn} onClick={onClickOnSetting}>
          <SettingsIcon />
        </IconButton>
        <IconButton className={classes.iconBtn} onClick={onClickOnNotification}>
          <NotificationsIcon />
        </IconButton>
        <IconButton className={classes.iconBtn} onClick={onClickOnFavorite}>
          <FavoriteBorderOutlinedIcon />
        </IconButton>
      </div>
      <div className={classes.userSection} onClick={onClickOnUserSection}>
        <div dir="ltr">
          {token ? (
            <>
              <Typography variant="body1" className={classes.hi}>
                {'سلام'}
              </Typography>
              <Typography variant="h6">{data.name}</Typography>
            </>
          ) : (
            <Typography variant="body1" className={classes.hi}>
              {'ورود/ثبت‌نام'}
            </Typography>
          )}
        </div>
        <img src={imageUrl} alt="user-image" className={classes.userAvatar} />
      </div>
    </div>
  )
}

export default HomeHeader

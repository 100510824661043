import {
  ButtonBase,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { MenuItemDetails } from '../../types/homePage'
import { useEffect } from 'react'
import { useStyles } from './styles/index'

interface MenuItemProps {
  data: MenuItemDetails
  lastItem?: boolean
}

export const MenuItem: React.FC<MenuItemProps> = ({ data, lastItem }) => {
  const classes = useStyles()
  const typeClass = classes[data.type]
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {}, [])

  return (
    <ButtonBase
      className={[
        `${classes.root} ${typeClass}`,
        lastItem && !isSmall && classes.lastItem,
        lastItem && isSmall && classes.lastItemSmall
      ].join(' ')}
      disabled={data.disabled}
      onClick={() => {
        data.onClick()
      }}>
      <div className={`${classes.root} ${typeClass}`}>
        <div className={classes.iconContainer}>
          {data.image ? (
            <img
              src={data.image}
              alt="image-for-options"
              className={classes.image}
            />
          ) : (
            <IconButton>{data.icon}</IconButton>
          )}
        </div>
        <div className={classes.innerDiv}>
          <Typography
            variant={isSmall ? 'h5' : 'h3'}
            className={
              data.disabled
                ? classes.primaryMainColorDisabled
                : classes.primaryMainColor
            }>
            {data.title}
          </Typography>
          <Typography
            variant="body2"
            className={data.disabled ? classes.primaryMainColorDisabled : ''}>
            {data.subtitle}
          </Typography>
        </div>
      </div>
    </ButtonBase>
  )
}

export default MenuItem

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.light,
    padding: '0.5rem 1rem',
    borderRadius: 16,
    marginTop: '1rem',
    marginBottom: '5rem'
  },
  title: {
    marginBottom: '1rem!important',
    color: theme.palette.primary.main
  },
  reviewSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between!important',
    alignItems: 'center',
    marginBottom: '0.5rem!important',
    width: '100%'
  },
  titleSmall: {
    fontSize: '0.8rem!important',
    color: theme.palette.primary.main
  },
  timerAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '0.25rem!important'
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%'
  },
  titleConfirm: {
    fontSize: '0.8rem!important',
    color: theme.palette.success.main
  },
  buttonPaymentContainer: {
    marginTop: '1rem',
    marginBottom: '0.5rem'
  },
  buttonPayment: {
    borderRadius: '1rem!important',
    background: `${theme.palette.success.dark}!important`,
    padding: '0.4rem 1.5rem !important'
  },
  buttonCancel: {
    borderRadius: '1rem!important',
    background: `${theme.palette.warning.dark}!important`,
    padding: '0.4rem 1.5rem !important'
  }
}))

import { Button, Modal, Typography } from '@mui/material'
import { addCommaToPrice } from '../../helpers/numbers'
import { useStyles } from './styles/index'

interface PreviewProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  result: string
  fee: string
  totalPrice: string
}

function Invoice({
  isOpen,
  setIsOpen,
  title,
  result,
  fee,
  totalPrice
}: PreviewProps) {
  const classes = useStyles()

  return (
    <Modal
      open={isOpen}
      dir="rtl"
      className={classes.modal}
      onClose={() => {
        setIsOpen(false)
      }}>
      <div className={classes.root}>
        <div className={classes.midSection}>
          <div className={classes.midSectionRow}>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'کالا:'}
                &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.textColor}>
                {title}
              </Typography>
            </div>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'میزان:'}
                &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.textColor}>
                {result}
              </Typography>
            </div>
          </div>
          <div className={classes.midSectionRow}>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'قیمت واحد:'}
                &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.textColor}>
                {addCommaToPrice(fee)}
                {'تومان'}
              </Typography>
            </div>
          </div>
          <div className={classes.midSectionRow}>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'مبلغ کل:'}
                &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.textColor}>
                {addCommaToPrice(totalPrice)}
                {'تومان'}
              </Typography>
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
          variant="contained"
          className={classes.button}>
          <Typography variant="body1">{'بستن'}</Typography>
        </Button>
      </div>
    </Modal>
  )
}

export default Invoice

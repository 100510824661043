import { Button, Modal, Typography } from '@mui/material'
import { useStyles } from './styles/index'
import { addCommaToPrice } from '../../helpers/numbers'
import { useMemo } from 'react'
import { OrderRequest, OrderResponse } from '../../types/coin'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'

interface PreviewProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>
  weight: number
  fee: number
  totalPrice: number
  buy: boolean
  id: number
  setOrderNumber: React.Dispatch<React.SetStateAction<number>>
}

function Preview({
  isOpen,
  setIsOpen,
  setIsConfirmOpen,
  weight,
  fee,
  totalPrice,
  buy,
  id,
  setOrderNumber
}: PreviewProps) {
  const classes = useStyles()
  let dateTime = useMemo(() => {
    return new Date()
  }, [])

  const onClickContinueButton = async (body: OrderRequest) => {
    const { data, error } = await apiRequest<OrderResponse>('/api/ordermc', {
      method: 'POST',
      body: body
    })

    if (data?.status) {
      toast.success('Order number: ' + data.data.orderNumber.toString())
      setIsOpen(false)
      setIsConfirmOpen(true)
      setOrderNumber(data.data.orderNumber)
    } else {
      toast.error(error || 'Buy failed. Please try again.')
    }
  }

  return (
    <Modal
      open={isOpen}
      dir="rtl"
      className={classes.modal}
      onClose={() => {
        setIsOpen(false)
      }}>
      <div className={classes.root}>
        <div className={classes.midSection}>
          <div className={classes.midSectionRow}>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'کالا:'}
                &nbsp;
              </Typography>
              <Typography variant="caption" className={classes.textColor}>
                {`${weight} گرم طلا`}
              </Typography>
            </div>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'ساعت:'}
                &nbsp;
              </Typography>
              <Typography
                variant="caption"
                className={
                  classes.textColor
                }>{`${dateTime.getHours()}:${dateTime.getMinutes()}`}</Typography>
            </div>
          </div>
          <div className={classes.midSectionRow}>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'قیمت هر گرم:'}
                &nbsp;
              </Typography>
              <Typography
                variant="caption"
                className={buy ? classes.textColorBuy : classes.textColorSell}>
                {addCommaToPrice(fee)}
                {'تومان'}
              </Typography>
            </div>
            <div className={classes.child}>
              <Typography variant="caption" className={classes.textColor}>
                {'مبلغ کل:'}
                &nbsp;
              </Typography>
              <Typography
                variant="caption"
                className={buy ? classes.textColorBuy : classes.textColorSell}>
                {addCommaToPrice(totalPrice)}
                {'تومان'}
              </Typography>
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            onClickContinueButton({
              order_type: buy ? 'buy' : 'sell',
              product_id: id,
              quantity: weight,
              type: 'melt'
            })
          }}
          variant="contained"
          className={classes.button}>
          <Typography variant="body1">{'ادامه'}</Typography>
        </Button>
      </div>
    </Modal>
  )
}

export default Preview

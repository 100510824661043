import Header from '../../components/Header'
import CartItem from '../../components/CartItem'
import { Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  ActionType,
  ChartPeriod,
  Melted,
  MeltedGoldResponse
} from '../../types/meltedGold'
import MyChart from '../../components/chart'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'

function MeltedGold() {
  const classes = useStyles()
  const [data, setData] = useState<Melted | null>(null)
  const [chartPeriod, setChartPeriod] = useState<ChartPeriod>('day')
  const [actionType, setActionType] = useState<ActionType>('buy')
  const buy = actionType === 'buy'
  const [dataForChart, setDataForChart] = useState<number[]>([
    10, 12, 14, 7, 8, 9, 15, 19, 16, 20, 11, 7, 16, 10, 12, 14, 7, 8, 19, 16,
    20, 11
  ])
  const [labelForChart, setLabelForChart] = useState<string[]>([
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM'
  ])

  const handleChangeTopTabs = (
    event: React.SyntheticEvent,
    newValue: ChartPeriod
  ) => {
    setChartPeriod(newValue)
  }

  const handleChangeBottomTabs = (
    event: React.SyntheticEvent,
    newValue: ActionType
  ) => {
    setActionType(newValue)
  }

  useEffect(() => {
    if (chartPeriod === 'day') {
      setDataForChart([
        10, 12, 14, 7, 8, 9, 15, 19, 16, 20, 11, 7, 16, 10, 12, 14, 7, 8, 19,
        16, 20, 11
      ])
      setLabelForChart([
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM'
      ])
    } else if (chartPeriod === 'week') {
      setDataForChart([17, 15, 16, 14, 15, 13, 12])
      setLabelForChart(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])
    } else if (chartPeriod === 'month') {
      setDataForChart([
        10, 12, 14, 7, 8, 9, 15, 19, 16, 20, 11, 7, 16, 10, 12, 14, 7, 8, 19,
        16, 20, 11, 19, 16, 20, 11, 7, 16
      ])
      setLabelForChart([
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31'
      ])
    }
  }, [chartPeriod])

  const fetchMeltedGold = async () => {
    const { data, error } = await apiRequest<MeltedGoldResponse>(
      '/api/meltgold',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData(data.data[0])
    } else {
      toast.error(error || 'Get melted gold list failed. Please try again.')
    }
  }

  useEffect(() => {
    fetchMeltedGold()
  }, [])

  return (
    <div className={classes.root}>
      <Header existIcons title={'خرید و فروش طلای آب شده (بدون اجرت)'} />
      <div className={classes.content}>
        <div className={classes.topTabsContainer}>
          <Tabs
            onChange={handleChangeTopTabs}
            value={chartPeriod}
            className={classes.topTabs}>
            <Tab
              label={'24 ساعت اخیر'}
              value={'day'}
              className={classes.topTabText}
            />
            <Tab
              label={'هفته اخیر'}
              value={'week'}
              className={classes.topTabText}
            />
            <Tab
              label={'ماه گذشته'}
              value={'month'}
              className={classes.topTabText}
            />
          </Tabs>
        </div>
        <div className={classes.chartSection}>
          <MyChart dataForChart={dataForChart} labelForChart={labelForChart} />
          <div className={classes.chartSectionTexts}>
            <Typography className={classes.title1}>
              {'39,980,000تومان'}
            </Typography>
            <Typography className={classes.title2}>
              {'قیمت 1 گرم طلای 18 عیار'}
            </Typography>
            <Typography className={classes.title3}>
              {'معادل 1000 میلی'}
            </Typography>
          </div>
        </div>
        <div className={classes.bottomTabsContainer}>
          <Tabs
            onChange={handleChangeBottomTabs}
            value={actionType}
            className={classes.bottomTabs}>
            <Tab
              label={'خرید'}
              value={'buy'}
              className={classes.bottomTabText}
            />
            <Tab
              label={'فروش'}
              value={'sell'}
              className={classes.bottomTabText}
            />
          </Tabs>
        </div>
        <div className={classes.cartItemContainer}>
          <div className={classes.cartItem}>
            <CartItem
              buy={buy}
              id={data?.id || 0}
              price={buy ? data?.price_buy || 0 : data?.price_sell || 0}
              title={data?.title || ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeltedGold

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '16rem'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  input: {
    direction: 'rtl'
  },
  icon: {
    cursor: 'pointer'
  },
  helperText: {
    textAlign: 'right !important' as 'right'
  },
  forgetPass: {
    direction: 'rtl',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))

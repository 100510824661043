import Welcome from '../screens/Welcome'
import PanelRoutes from './panelRoutes'

export default function Routes() {
  return true ? <PanelRoutes /> : <Welcome />
}

// import { useReactiveVar } from '@apollo/client'

// import { credentialsVar, loadingSessionStorage } from '../cache/auth'
// import MainDrawer from '../screens/mainDrawer'
// import AuthRoutes from './authRoutes'

// export default function Routes() {
//   const credentials = useReactiveVar(credentialsVar)
//   const loading = useReactiveVar(loadingSessionStorage)

//   if (loading && !credentials) {
//     return null
//   }

//   return credentials?.accessToken ? <MainDrawer /> : <AuthRoutes />
// }

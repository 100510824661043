import { Button, Typography } from '@mui/material'
import logo from '../../assets/logo.svg'
import { Order } from '../../types/orders'
import Invoice from '../Invoice'
import { useState } from 'react'
import PaymentMethodsModal from '../PaymentModal'
import { useStyles } from './styles/index'

type Props = {
  item: Order
  getOrders: () => Promise<void>
}

function OrderItem({ item, getOrders }: Props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const calcQuantity = (quantity: number, type: string) => {
    if (type.includes('MeltGold')) {
      return `${quantity}  گرم`
    } else {
      return `${quantity}  عدد`
    }
  }

  const result = calcQuantity(item.quantity, item.orderable_type)

  const buttonTitle = () => {
    switch (item.status) {
      case 'pending':
        return 'مشاهده فاکتور'
      case 'accepted':
        return 'پرداخت'
      case 'canceled':
        return 'خرید مجدد'
      case 'completed':
        return 'مشاهده فاکتور'
      default:
        return 'مشاهده فاکتور'
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.upSection}>
        <img
          src={
            item.orderable.photo
              ? 'https://panel.zozangallery.com/' + item.orderable.photo
              : logo
          }
          alt="user-image-profile"
          className={classes.userAvatar}
        />
        <div>
          <Typography className={classes.productName}>
            {item.orderable.title}
          </Typography>
          <Typography className={classes.productName}>{result}</Typography>
          <Typography className={classes.productName}>
            {item.total_price}
          </Typography>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={() => {
            if (item.status === 'pending') {
              setIsOpen(true)
            } else if (item.status === 'accepted') {
              setOpenModal(true)
            }
          }}
          variant="contained"
          className={classes.btn}>
          <Typography variant="caption">{buttonTitle()}</Typography>
        </Button>
      </div>
      {isOpen && (
        <Invoice
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={item.orderable.title}
          result={result}
          fee={item.price}
          totalPrice={item.total_price}
        />
      )}
      {openModal && (
        <PaymentMethodsModal
          open={openModal}
          setOpen={setOpenModal}
          id={item.id}
          getOrders={getOrders}
        />
      )}
    </div>
  )
}

export default OrderItem

function addCommaToPriceUnsigned(price: string | number) {
  if (price === 0) {
    return price
  }
  if (!price) {
    return ''
  }
  const splittedPrice = price.toString().split('.')
  if (splittedPrice[0].toString().length > 3) {
    const formattedPrice = splittedPrice[0]
      .toString()
      .split('')
      .reverse()
      .join('')
      .replace(/.{3}/g, '$&,')
      .split('')
      .reverse()
      .join('')
    if (formattedPrice.toString().charAt(0) === ',') {
      return `${formattedPrice.substr(1)}${
        splittedPrice[1] ? `.${splittedPrice[1]}` : ''
      }`
    }
    return `${formattedPrice}${splittedPrice[1] ? `.${splittedPrice[1]}` : ''}`
  }
  return price
}

export function addCommaToPrice(price: string | number) {
  if (price && price.toString().length > 0 && price.toString()[0] === '-') {
    return `${addCommaToPriceUnsigned(price.toString().substring(1))}-`
  }
  return addCommaToPriceUnsigned(price)
}

export function removeCommaFromPrice(price: string | number) {
  return price.toString().replaceAll(',', '')
}

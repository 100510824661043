import { Typography } from '@mui/material'
import Header from '../../components/Header'
import TextAreaInput from '../../components/TextAreaInput'
import { ProfileResponse, UserInfoInputs } from '../../types/userInfo'
import { useEffect, useState } from 'react'
import { AdapterMomentJalaali } from '@mui/x-date-pickers/AdapterMomentJalaali'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { faIR } from '@mui/x-date-pickers/locales'
import moment from 'moment-jalaali'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'

function UserInfo() {
  const classes = useStyles()
  const [data, setData] = useState<UserInfoInputs>({
    name: '',
    lastName: '',
    birthDate: null,
    nationalCode: '',
    phoneNumber: ''
  })

  const eighteenYearsAgo = moment()
    .subtract(18, 'years')
    .add(1, 'days')
    .format('YYYY/MM/DD')

  const GetData = async () => {
    const { data, error } = await apiRequest<ProfileResponse>(
      '/api/get-profile',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData({
        name: data.data.name,
        lastName: data.data.lastName,
        birthDate: data.data.birthDate,
        nationalCode: data.data.nationalCode,
        phoneNumber: data.data.mobile
      })
    } else {
      toast.error(error || 'Get profile data failed. Please try again.')
    }
  }

  useEffect(() => {
    moment.loadPersian({ usePersianDigits: false })
    GetData()
  }, [])

  return (
    <div className={classes.root}>
      <Header title={'اطلاعات کاربری'} />
      <div className={classes.inputs}>
        <TextAreaInput
          title={'نام'}
          placeholder={'نام خود را وارد نمایید'}
          type={'text'}
          value={data.name}
          disabled
        />
        <TextAreaInput
          title={'نام خانوادگی'}
          placeholder={'نام خانوادگی خود را وارد نمایید'}
          type={'text'}
          value={data.lastName}
          disabled
        />
        <div>
          <Typography variant="h6">{'تاریخ تولد'}</Typography>
          <LocalizationProvider
            dateAdapter={AdapterMomentJalaali}
            adapterLocale="fa"
            localeText={
              faIR.components.MuiLocalizationProvider.defaultProps.localeText
            }>
            <DatePicker
              value={
                data.birthDate ? moment(data.birthDate, 'YYYY/MM/DD') : null
              }
              onChange={() => {}}
              format="jYYYY/jMM/jDD"
              disableFuture
              maxDate={moment(eighteenYearsAgo)}
              sx={{
                '.MuiInputBase-root': {
                  width: '16rem'
                }
              }}
              disabled
            />
          </LocalizationProvider>
        </div>
        <TextAreaInput
          title={'کد ملی'}
          placeholder={'کد ملی خود را وارد نمایید'}
          type={'number'}
          value={data.nationalCode}
          disabled
        />
        <TextAreaInput
          title={'شماره تلفن'}
          placeholder={'شماره تلفن خود را وارد نمایید'}
          type={'number'}
          value={data.phoneNumber}
          disabled
        />
      </div>
    </div>
  )
}

export default UserInfo

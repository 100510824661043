import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5
  },
  price: {
    color: theme.palette.success.dark,
    marginTop: 10
  },
  img: {
    width: '60%',
    marginBottom: 30
  }
}))

import { IconButton, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useStyles } from './styles/index'

export default function UploadBox() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <IconButton>
        <CloudUploadIcon />
      </IconButton>
      <Typography variant="caption">
        {'برای بارگذاری فایل خود '}
        <span className={classes.hereColor}>اینجا</span>
        {' کلیک کنید'}
      </Typography>
    </div>
  )
}

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 2fr 2fr 1fr',
    gap: 2,
    // background: theme.palette.secondary.main,
    background: '#F7E7C8',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '1rem',
    padding: '0.5rem 0.5rem'
  },
  price: {
    color: theme.palette.success.dark,
    marginTop: 10
  },
  imgContainer: {
    // background: theme.palette.secondary.main
  },
  img: {
    width: '100%',
    maxWidth: 80,
    maxHeight: 80
  },
  buyBtn: {
    backgroundColor: `${theme.palette.success.dark}!important`,
    color: theme.palette.common.white,
    borderRadius: '1rem!important'
  }
}))

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  menuItemsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gap: 10,
    marginTop: '2rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '6rem'
  },
  iconOfItem: {
    fontSize: '3.5rem!important'
  }
}))

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    alignItems: 'center',
    width: '100%',
    minWidth: '30vw',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw'
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '20vw'
    }
  },
  rightIcons: {
    display: 'flex'
  },
  iconBtnFirst: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.dark}!important`,
    marginLeft: '0.25rem!important'
  },
  icon: {
    fontSize: '1rem!important'
  },
  iconBtn: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.dark}!important`
  },
  text: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    flex: '1'
  },
  backIcon: {
    color: `${theme.palette.primary.main}!important`
  }
}))

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
    borderRadius: '16px',
    backgroundColor: theme.palette.background.default
  },
  iconTopRightContainer: {
    display: 'flex',
    gap: 5
  },
  iconBtn: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.dark}!important`
  },
  homeText: {
    color: theme.palette.primary.main
  },
  userSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer'
  },
  hi: {
    color: theme.palette.primary.main
  },
  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`
  }
}))

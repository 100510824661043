import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import { IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles/index'

type Props = {
  existIcons?: boolean
  title: string
}

export default function Header({ existIcons, title }: Props) {
  const classes = useStyles()
  const navigate = useNavigate()

  const onClick = () => {
    navigate(-1)
  }

  return (
    <div className={classes.header}>
      <div className={classes.rightIcons}>
        {existIcons && (
          <>
            <IconButton className={classes.iconBtnFirst}>
              <TuneIcon className={classes.icon} />
            </IconButton>
            <IconButton className={classes.iconBtn}>
              <SearchIcon className={classes.icon} />
            </IconButton>
          </>
        )}
      </div>
      <Typography className={classes.text} variant={existIcons ? 'h5' : 'h2'}>
        {title}
      </Typography>
      <IconButton className={classes.backIcon} onClick={onClick}>
        <ArrowBackIosNewIcon />
      </IconButton>
    </div>
  )
}

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { theme } from '../../theme'
import { useStyles } from './styles/index'

interface Props {
  activeStep: number
  steps: string[]
  success?: boolean
}

export default function Index({ activeStep, steps, success }: Props) {
  const classes = useStyles()

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <div
            className={[
              classes.connector,
              success && classes.connectorSuccess,
              activeStep < steps.length - 1 && classes.incompleteConnector
            ].join(' ')}
          />
        }>
        {steps.map((label, index) => (
          <Step
            key={index}
            sx={{
              '& .MuiStepIcon-root.Mui-active': {
                color: success
                  ? theme.palette.success.main
                  : theme.palette.warning.main
              },
              '& .MuiStepIcon-root.Mui-completed': {
                color: success
                  ? theme.palette.success.main
                  : theme.palette.warning.main
              }
            }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

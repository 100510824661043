import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  menuItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    marginTop: '2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  iconOfItem: {
    fontSize: '3.5rem!important'
  }
}))

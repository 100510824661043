import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Theme } from '@mui/material/styles'
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider'
import { ToastContainer } from 'react-toastify' // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css' // Import toast styles
import { theme } from './theme'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

function MainRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  )
}

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          rtl
          toastStyle={{
            fontFamily: 'IRANSans'
          }}
        />
        <div
          style={{
            backgroundColor: theme.palette.grey[300],
            height: '100vh'
          }}>
          <div
            style={{
              direction: 'rtl',
              maxWidth: 540,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              margin: '0 auto',
              padding: '2rem',
              width: '100%',
              height: '100vh',
              overflow: 'auto',
              backgroundColor: theme.palette.primary.contrastText,
              paddingTop: 10
            }}>
            <MainRoutes />
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App

import { ButtonBase, Typography } from '@mui/material'
import { addCommaToPrice } from '../../helpers/numbers'
import { useStyles } from './styles/index'

export type ProductCardItem = {
  imageURL: string
  title: string
  price: number
  code: string
  onClick: () => void
}
interface ProductCardProps {
  data: ProductCardItem
}
export const ProductCard: React.FC<ProductCardProps> = ({ data }) => {
  const classes = useStyles()

  return (
    <ButtonBase
      onClick={() => {
        data.onClick()
      }}>
      <div className={classes.root}>
        <img src={data.imageURL} alt={data.title} className={classes.img} />
        <Typography variant="body1">{data.code}</Typography>
        <Typography variant="h6">{data.title}</Typography>
        <Typography
          variant="body2"
          className={classes.price}>{`${addCommaToPrice(
          data.price
        )} تومان`}</Typography>
      </div>
    </ButtonBase>
  )
}

export default ProductCard

import { Button } from '@mui/material'
import Header from '../../components/Header'
import TextAreaInput from '../../components/TextAreaInput'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { apiRequest } from '../../helpers/request'
import { SignUpResponse } from '../../types/signUp'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'

function VerificationCode() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)

  const onClickLoginButton = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<SignUpResponse>(
      '/api/verify-otp-login',
      {
        method: 'POST',
        body: {
          phone: localStorage.getItem('mobile'),
          otp: code
        }
      }
    )

    setLoading(false)

    if (data?.status) {
      toast.success(data.message)
      localStorage.setItem('authToken', data.data.token)
      navigate('/home')
    } else {
      toast.error(error || 'Verifying OTP failed. Please try again.')
    }
  }

  return (
    <div className={classes.root}>
      <Header title={'دریافت کد'} />
      <div className={classes.inputs}>
        <TextAreaInput
          title={'دریافت کد'}
          placeholder={'کد دریافتی خود را وارد کنید'}
          type={'number'}
          value={code}
          onChange={(val) => {
            setCode(val)
          }}
        />
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!code || loading}
            onClick={onClickLoginButton}>
            {'ورود'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerificationCode

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  uploadSection: {
    marginTop: '1.5rem'
  },
  input: {
    background: theme.palette.error.light,
    borderRadius: 4,
    '& ::placeholder': {
      fontSize: 12,
      color: `${theme.palette.primary.dark}!important`
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '5rem'
  },
  buttonContainerSmall: {
    marginTop: '10rem'
  },
  button: {
    borderRadius: '1rem!important',
    background: `${theme.palette.primary.main}!important`
  },
  progressStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  progressStepText: {
    color: `${theme.palette.primary.main}!important`
  },
  progressStepImg: {
    marginTop: '1rem',
    width: '60%'
  }
}))

import { useNavigate } from 'react-router-dom'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { IconButton } from '@mui/material'
import { footerType } from '../../types/footer'
import { useStyles } from './styles/index'

type FooterProps = {
  step: footerType | undefined
}

function Footer({ step }: FooterProps) {
  const classes = useStyles()
  const navigate = useNavigate()

  const onClickHome = () => {
    navigate('/home')
  }

  const onClickChat = () => {
    navigate('/chat-room')
  }

  const onClickNotification = () => {
    navigate('/orders')
  }

  const onClickProfile = () => {
    navigate('/profile')
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={onClickHome}>
        <HomeOutlinedIcon
          className={[
            classes.icon,
            step === 'home' && classes.iconSelected
          ].join(' ')}
        />
      </IconButton>
      <IconButton onClick={onClickChat}>
        <QuestionAnswerOutlinedIcon
          className={[
            classes.icon,
            step === 'chat' && classes.iconSelected
          ].join(' ')}
        />
      </IconButton>
      <IconButton onClick={onClickNotification}>
        <EventNoteOutlinedIcon
          className={[
            classes.icon,
            step === 'orders' && classes.iconSelected
          ].join(' ')}
        />
      </IconButton>
      <IconButton onClick={onClickProfile}>
        <PersonOutlineOutlinedIcon
          className={[
            classes.icon,
            step === 'profile' && classes.iconSelected
          ].join(' ')}
        />
      </IconButton>
    </div>
  )
}

export default Footer

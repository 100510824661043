import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem 0rem'
  },
  icon: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.main}!important`,
    fontSize: '2rem!important',
    borderRadius: '50%',
    padding: '0.25rem'
  },
  iconContainer: {
    width: '2rem!important',
    height: '2rem!important',
    display: 'flex',
    alignItems: 'center'
  },
  userAvatar: {
    width: '7rem',
    height: '7rem',
    borderRadius: '50%'
  }
}))

import moment from 'moment'
import momentJalaali from 'moment-jalaali'
import jMoment from 'moment-jalaali'

export function convertPersianToEnglishDigits(persianDate: string): string {
  const persianToEnglishMap: { [key: string]: string } = {
    '۰': '0',
    '۱': '1',
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9'
  }

  return persianDate.replace(/[۰-۹]/g, (char) => persianToEnglishMap[char])
}

export function gregorianToJalaali(gregorianDate: string): string {
  return momentJalaali(gregorianDate, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
}

jMoment.loadPersian({ usePersianDigits: true, dialect: 'persian-modern' }) // Ensure Persian names for months

export const getFormattedDate = (dateString: string): string => {
  const inputDate = moment(dateString, 'YYYY-MM-DD HH:mm:ss')
  const now = moment()

  const isToday = now.isSame(inputDate, 'day')

  if (isToday) {
    return 'امروز'
  } else {
    return jMoment(inputDate).format('jD jMMMM') // This will return "21 شهریور" or other months in Persian
  }
}

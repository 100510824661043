import Header from '../../components/Header'
import Cards, { Focused } from 'react-credit-cards'
import { useEffect, useState } from 'react'
import { BankCartResponse, CartInput } from '../../types/bankCart'
import 'react-credit-cards/es/styles-compiled.css'
import './styles/bankCart.css' // Import custom styles for LTR and label adjustments
import { GeneralResponse } from '../../types/general'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { useStyles } from './styles/index'

function BankCart() {
  const classes = useStyles()
  const [inputs, setInputs] = useState<CartInput>({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  })
  const [loading, setLoading] = useState(false)
  const [exist, setExist] = useState(false)

  const handleInputFocus = (e: any) => {
    setInputs({ ...inputs, focus: e.target.name })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setInputs({ ...inputs, [name]: value })
  }

  const GetCartBankData = async () => {
    setLoading(true)
    const { data } = await apiRequest<BankCartResponse>('/api/cart-bank-data', {
      method: 'GET'
    })
    setLoading(false)

    if (data?.status) {
      setExist(true)
      setInputs({
        cvc: data.data.cvv_code,
        expiry: data.data.iban.replace('-', '/'),
        focus: '',
        name: data.data.fullName,
        number: data.data.account_number,
        id: data.data.id
      })
      toast.success(data?.message)
    } else {
      toast.info('کارت بانکی خود را تعریف کنید')
    }
  }

  const CartBankMutation = async () => {
    const { focus, id, ...body } = inputs
    body.cvc.replace('/', '-')
    setLoading(true)

    const { data, error } = await apiRequest<GeneralResponse>(
      exist ? `/api/cart-bank-data/${inputs.id}` : '/api/cart-bank-data',
      {
        method: exist ? 'PUT' : 'POST',
        body: {
          fullName: body.name,
          account_number: body.number,
          iban: body.expiry.replace('/', '-'),
          cvv_code: body.cvc
        }
      }
    )
    setLoading(false)

    if (data?.status) {
      toast.success(data?.message)
    } else {
      toast.error(error || 'Cart Bank failed. Please try again.')
    }
  }

  useEffect(() => {
    GetCartBankData()
  }, [])

  return (
    <div className={classes.root}>
      <Header title={'تعریف کارت بانکی'} />
      <div className={classes.cart}>
        <Cards
          cvc={inputs.cvc}
          expiry={inputs.expiry}
          focused={inputs.focus as Focused}
          name={inputs.name}
          number={inputs.number}
          locale={{
            valid: 'تاریخ انقضا'
          }}
          placeholders={{
            name: 'نام و نام‌ خانوادگی'
          }}
          preview
          issuer="Bank"
        />
      </div>
      <form className={classes.form}>
        <label>
          دارنده کارت
          <input
            type="text"
            name="name"
            placeholder="نام و نام خانوادگی"
            value={inputs.name}
            dir="rtl"
            className={classes.text}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </label>
        <label>
          شماره کارت
          <input
            type="tel"
            name="number"
            placeholder="شماره کارت خود را وارد کنید"
            value={inputs.number}
            dir="rtl"
            className={classes.text}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </label>
        <label>
          تاریخ انقضا
          <input
            type="tel"
            name="expiry"
            placeholder="برای مثال 03/12"
            value={inputs.expiry}
            dir="rtl"
            className={classes.text}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </label>
        <label>
          کد CVV2
          <input
            type="tel"
            name="cvc"
            placeholder="کد CVV2 را وارد کنید"
            value={inputs.cvc}
            dir="rtl"
            className={classes.text}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </label>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={
              !inputs.name ||
              !inputs.number ||
              !inputs.expiry ||
              !inputs.cvc ||
              loading
            }
            onClick={CartBankMutation}>
            {'تایید'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BankCart

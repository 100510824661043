import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  options: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '0.25rem',
    marginBottom: '1rem'
  },
  option: {
    background: theme.palette.primary.light,
    borderRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem 0rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  selectedOption: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'default'
  },
  itemsContainer: {
    paddingBottom: '6rem'
  }
}))

import HomeHeader from './Header'
import SearchBar from './SearchBar'
import logo from '../../assets/logo.svg'
import melted from '../../assets/melted.svg'
import coin from '../../assets/coin.svg'
import buy from '../../assets/buy.svg'
import repair from '../../assets/repair.svg'
import make from '../../assets/make.svg'
import { MenuItemDetails, MenuType } from '../../types/homePage'
import { useNavigate } from 'react-router-dom'
import MenuItem from '../../components/MenuItem'
import { apiRequest } from '../../helpers/request'
import { CategoryResponse } from '../../types/category'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import LoadingScreen from '../../components/LoadingScreen'
import { useStyles } from './styles/index'

function HomePage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [menuItems, setmenuItems] = useState<MenuItemDetails[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const typeOfMenuArray: MenuType[] = [
    'melted',
    'coin',
    'buy',
    'repair',
    'make',
    'buyGoldMenuItem',
    'buyCoinMenuItem'
  ]
  const imagesOfMenuArray = [melted, coin, buy, repair, make]
  const titleToRouteMap: Record<string, string> = {
    'خرید و فروش طلای آبشده': '/melted-gold',
    'خرید سکه': '/buy-coin',
    'خرید طلا': '/buy-gold',
    'تعمیرات طلا': '/repair-gold',
    'ساخت طلا': '/create-gold'
  }

  const fetchMenuItems = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<CategoryResponse>(
      '/api/category?cat_id=' + 0,
      {
        method: 'GET'
      }
    )
    setLoading(false)

    if (data?.status) {
      setmenuItems(
        data.data.map((cat, index) => ({
          icon: null,
          image: imagesOfMenuArray[index]
            ? imagesOfMenuArray[index]
            : 'https://panel.zozangallery.com/' + cat.photo || '',
          title: cat.title,
          subtitle: cat.summary || '',
          type: typeOfMenuArray[index],
          disabled: index !== 0 && index !== 1,
          onClick: () => {
            const route = titleToRouteMap[cat.title] || '/default-route'
            navigate(route)
          }
        }))
      )
    } else {
      toast.error(error || 'Login failed. Please try again.')
    }
  }

  useEffect(() => {
    fetchMenuItems()
  }, [])

  // const menuItems: MenuItemDetails[] = [
  //   {
  //     icon: null,
  //     image: melted,
  //     title: 'خرید و فروش طلای آب شده ',
  //     subtitle: 'بدون اجرت طلای آب شده خرید و فروش کنید.',
  //     type: 'melted',
  //     onClick: () => {
  //       navigate('/melted-gold')
  //     }
  //   },
  //   {
  //     icon: null,
  //     image: coin,
  //     title: 'خرید سکه',
  //     subtitle: 'در این بخش سکه های موجود ما را خرید کنید.',
  //     type: 'coin',
  //     onClick: () => {
  //       navigate('/buy-coin')
  //     }
  //   },
  //   {
  //     icon: null,
  //     image: buy,
  //     title: 'خرید طلا',
  //     subtitle: 'در این بخش طلاهای موجود ما را خرید کنید.',
  //     type: 'buy',
  //     onClick: () => {
  //       navigate('/buy-gold')
  //     }
  //   },
  //   {
  //     icon: null,
  //     image: repair,
  //     title: 'تعمیرات طلا',
  //     subtitle: 'در این بخش تعمیرات طلای خود را ثبت کنید.',
  //     type: 'repair',
  //     onClick: () => {
  //       navigate('/repair-gold')
  //     }
  //   },
  //   {
  //     icon: null,
  //     image: make,
  //     title: 'ساخت طلا',
  //     subtitle: 'در این بخش طلاهای مورد نیاز خود را بسازید.',
  //     type: 'make',
  //     onClick: () => {
  //       navigate('/create-gold')
  //     }
  //   }
  // ]

  return (
    <div className={classes.root}>
      <HomeHeader imageUrl={logo} />
      {/* <SearchBar /> */}
      <div className={classes.menuItemsContainer}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            data={item}
            lastItem={menuItems.length - 1 === index}
          />
        ))}
      </div>
      <LoadingScreen open={loading} numberOfCircles={4} />
    </div>
  )
}

export default HomePage

import { useNavigate, useParams } from 'react-router-dom'
import ProductCard, { ProductCardItem } from '../../components/productCard'
import ringURL from '../../assets/ring.png'
import Header from '../../components/Header'
import { getTranslate } from '../../helpers/translate'
import { useStyles } from './styles/index'

function BuyGoldProducts() {
  const classes = useStyles()
  const { subpage, gallary } = useParams()
  const navigate = useNavigate()

  //TODO: this list should be fetched from an API with subpage parameter
  const products: ProductCardItem[] = [
    {
      title: 'انگشتر فرد سه نگین',
      code: 'AR517',
      price: 15456000,
      imageURL: ringURL,
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${subpage}/products/rings/${'AR517'}`)
      }
    },
    {
      title: 'انگشتر آریان',
      code: 'AR503',
      price: 17201000,
      imageURL: ringURL,
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${subpage}/products/rings/${'AR517'}`)
      }
    },
    {
      title: 'انگشتر آدریان سنگخور  ',
      code: 'AR495',
      price: 13417000,
      imageURL: ringURL,
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${subpage}/products/rings/${'AR517'}`)
      }
    },
    {
      title: 'انگشتر اتمی سنگ ',
      code: 'AR403',
      price: 7033000,
      imageURL: ringURL,
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${subpage}/products/rings/${'AR517'}`)
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Header title={getTranslate(subpage || '')} existIcons />
      {/* TODO: implement header and sort/filter to the tope of menuItems */}
      <div className={classes.menuItemsContainer}>
        {products.map((item, index) => (
          <ProductCard key={index} data={item} />
        ))}
      </div>
    </div>
  )
}
// TODO: this page needs pagination

export default BuyGoldProducts

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  stepperContainer: {
    marginTop: '1rem',
    width: '100%'
  },
  inputs: {
    width: '100%',
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    width: '100%',
    marginBottom: '1rem!important',
    textAlign: 'right'
  },
  title2: {
    width: '100%',
    textAlign: 'right'
  },
  repeatedPass: {
    marginTop: '1rem!important'
  },
  otpInputs: {
    width: '80%'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem'
  },
  button: {
    width: '14rem!important',
    borderRadius: '1rem!important',
    fontSize: '1.25rem!important'
  },
  alreadyHaveAcc: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '1rem'
  },
  login: {
    marginRight: '0.5rem!important',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  modify: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  rules: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '1rem'
  },
  ruleText: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1rem',
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  markAll: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  dateInfo: {
    color: theme.palette.primary.main
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '2rem',
    padding: '0.5rem 0rem',
    margin: '0.5rem 0rem',
    background: theme.palette.primary.light
  },
  mainSectionRead: {
    background: theme.palette.primary.contrastText
  },
  texts: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

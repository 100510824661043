import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  inputs: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem'
  },
  button: {
    width: '14rem!important',
    borderRadius: '1rem!important',
    fontSize: '1.25rem!important'
  }
}))

import MenuItem from '../../components/MenuItem'
import { useNavigate } from 'react-router-dom'
import { MenuItemDetails } from '../../types/homePage'
import Header from '../../components/Header'
import { useStyles } from './styles/index'

function BuyCoinPage() {
  const classes = useStyles()
  const navigate = useNavigate()

  const menuItems: MenuItemDetails[] = [
    {
      icon: null,
      title: 'سکه امامی',
      subtitle: 'خرید سکه‌های امامی را اینجا ثبت نمایید.',
      type: 'buyCoinMenuItem',
      onClick: () => {
        navigate(`/buy-coin/${'imami-coin'}/products`)
      }
    },
    {
      icon: null,
      title: 'سکه پارسیان',
      subtitle: 'خرید سکه‌های پارسیان را اینجا ثبت نمایید.',
      type: 'buyCoinMenuItem',
      onClick: () => {
        navigate(`/buy-coin/${'parsian-coin'}/products`)
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Header title={'خرید سکه'} existIcons />
      {/* TODO: implement header and sort/filter to the tope of menuItems */}
      <div className={classes.menuItemsContainer}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} data={item} />
        ))}
      </div>
    </div>
  )
}

export default BuyCoinPage

import Header from '../../components/Header'
import FavoriteItem from '../../components/FavoriteItem'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { CartItem, FavoriteResponse } from '../../types/favorites'
import { useStyles } from './styles/index'

function Favorites() {
  const classes = useStyles()
  const [data, setData] = useState<CartItem[]>([])

  const getFavorites = async () => {
    const { data, error } = await apiRequest<FavoriteResponse>(
      '/api/wishlist',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData(data.data)
    } else {
      toast.error(error || 'Get favorite list fails. Please try again.')
    }
  }

  useEffect(() => {
    getFavorites()
  }, [])

  return (
    <div className={classes.root}>
      <Header title={'علاقه‌مندی‌ها'} />
      {data.map((item) => (
        <FavoriteItem item={item} />
      ))}
    </div>
  )
}

export default Favorites

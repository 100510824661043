import DiamondIcon from '@mui/icons-material/Diamond'
import MaleIcon from '@mui/icons-material/Male'
import FemaleIcon from '@mui/icons-material/Female'
import ChildCareIcon from '@mui/icons-material/ChildCare'
import DiscountIcon from '@mui/icons-material/Discount'
import MenuItem from '../../components/MenuItem'
import { useNavigate, useParams } from 'react-router-dom'
import { MenuItemDetails } from '../../types/homePage'
import Header from '../../components/Header'
import { useStyles } from './styles/index'

function BuyGoldSelectSubPage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { gallary } = useParams()

  const menuItems: MenuItemDetails[] = [
    {
      icon: <FemaleIcon className={classes.iconOfItem} />,
      title: 'زنانه',
      subtitle: 'تمامی طلاهای زنانه را اینجا جست و جو کنید.',
      type: 'buyGoldMenuItem',
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${'women-jewelry'}`)
      }
    },
    {
      icon: <MaleIcon className={classes.iconOfItem} />,
      title: 'مردانه',
      subtitle: 'تمامی طلاهای مردانه را اینجا جست و جو کنید.',
      type: 'buyGoldMenuItem',
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${'men-jewelry'}`)
      }
    },
    {
      icon: <ChildCareIcon className={classes.iconOfItem} />,
      title: 'کودک',
      subtitle: 'تمامی طلاهای کودک را اینجا جست و جو کنید.',
      type: 'buyGoldMenuItem',
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${'kid-jewelry'}`)
      }
    },
    {
      icon: <DiscountIcon className={classes.iconOfItem} />,
      title: 'پیشنهاد ویژه',
      subtitle: 'تمامی طلاهای تخفیف دار را اینجا جست و جو کنید.',
      type: 'buyGoldMenuItem',
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${'special-offer-jewelry'}`)
      }
    },
    {
      icon: <DiamondIcon className={classes.iconOfItem} />,
      title: 'همه محصولات',
      subtitle: 'تمامی طلاهای موجود را اینجا جست و جو کنید.',
      type: 'buyGoldMenuItem',
      onClick: () => {
        navigate(`/buy-gold/${gallary}/${'all'}`)
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Header title={'خرید طلا'} existIcons />
      {/* TODO: implement header and sort/filter to the tope of menuItems */}
      <div className={classes.menuItemsContainer}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} data={item} />
        ))}
      </div>
    </div>
  )
}

export default BuyGoldSelectSubPage

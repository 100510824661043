import { Switch, Typography } from '@mui/material'
import Header from '../../components/Header'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useStyles } from './styles/index'

function NotificationSetting() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [notification, setNotification] = useState(true)
  const [sound, setSound] = useState(true)
  const [vibrate, setVibrate] = useState(true)

  return (
    <div className={classes.root}>
      <Header title={'تنظیمات اعلانات'} />
      <div className={classes.row}>
        <Typography variant="button">{'اظلاع رسانی'}</Typography>
        <div className={classes.iconContainer}>
          <Switch
            checked={notification}
            onChange={() => {
              setNotification(!notification)
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <Typography variant="button">{'صدا'}</Typography>
        <div className={classes.iconContainer}>
          <Switch
            checked={sound}
            onChange={() => {
              setSound(!sound)
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <Typography variant="button">{'لرزش'}</Typography>
        <div className={classes.iconContainer}>
          <Switch
            checked={vibrate}
            onChange={() => {
              setVibrate(!vibrate)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default NotificationSetting

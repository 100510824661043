import ReactECharts from 'echarts-for-react'
import { useStyles } from './styles/index'
import { theme } from '../../theme'

type GradientChartProps = {
  dataForChart: number[]
  labelForChart: string[]
}

const GradientChart = ({ dataForChart, labelForChart }: GradientChartProps) => {
  const classes = useStyles()

  const option = {
    xAxis: {
      type: 'category',
      boundaryGap: false, // For area chart with no gaps
      data: labelForChart, // Example labels
      show: false, // Hide X-axis labels
      axisLabel: {
        interval: 0 // Show all x-axis labels without skipping
      }
    },
    yAxis: {
      type: 'value',
      show: false // Hide Y-axis labels
    },
    series: [
      {
        data: dataForChart, // Example data points
        type: 'line',
        symbol: 'none', // Remove circular markers from each data point
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: theme.palette.primary.main // Color at the top of the gradient
              },
              {
                offset: 1,
                color: theme.palette.primary.contrastText // Color at the bottom of the gradient
              }
            ]
          }
        },
        lineStyle: {
          color: theme.palette.primary.main, // Set the color of the upper line
          width: 1 // Make the line visible by setting a width
        }
      }
    ],
    tooltip: {
      trigger: 'axis', // Show tooltip when hovering over the axis
      axisPointer: {
        type: 'line' // Show a vertical line to indicate the hovered position
      }
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: true // Ensure all labels and data points are visible
    }
    // dataZoom: [
    //   {
    //     type: 'slider', // Allows horizontal scrolling
    //     start: 0,
    //     end: 100
    //   }
    // ]
  }

  return (
    <div className={classes.root}>
      <ReactECharts option={option} />
    </div>
  )
}

export default GradientChart

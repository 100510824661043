import { Button } from '@mui/material'
import Header from '../../components/Header'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import TextAreaInput from '../../components/TextAreaInput'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { apiRequest } from '../../helpers/request'
import { SignUpResponse } from '../../types/signUp'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'

function VerificationCodeForPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [repeatedPass, setRepeatedPass] = useState('')
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/
  const isValidPassword = (password: string): boolean => {
    return passwordRegex.test(password)
  }

  const handleClickInput = () => {
    setIsVisible(!isVisible)
  }

  const onClickButton = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<SignUpResponse>(
      '/api/forgetpass',
      {
        method: 'POST',
        body: {
          phone: localStorage.getItem('mobile'),
          otp_code: code,
          new_password: password
        }
      }
    )

    setLoading(false)

    if (data?.status) {
      toast.success(data.message)
      navigate('/login')
    } else {
      toast.error(error || 'Verifying OTP failed. Please try again.')
    }
  }

  return (
    <div className={classes.root}>
      <Header title={'دریافت کد'} />
      <div className={classes.inputs}>
        <TextAreaInput
          title={'دریافت کد'}
          placeholder={'کد دریافتی خود را وارد کنید'}
          type={'number'}
          value={code}
          onChange={(val) => {
            setCode(val)
          }}
        />
        <TextAreaInput
          title={'کلمه عبور'}
          placeholder={'کلمه عبور خود را وارد نمایید'}
          icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClick={handleClickInput}
          type={isVisible ? 'text' : 'password'}
          value={password}
          helperText={
            isValidPassword(password)
              ? ''
              : 'کلمه عبور باید حداقل شامل هشت حرف باشد که یک حرف بزرگ و یه کاراکتر ویژه هم داشته باشد.'
          }
          onChange={(val) => {
            setPassword(val)
          }}
        />
        <div>
          <TextAreaInput
            title={'تکرار کلمه عبور'}
            placeholder={'کلمه عبور خود را تکرار کنید'}
            icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onClick={handleClickInput}
            type={isVisible ? 'text' : 'password'}
            value={repeatedPass}
            helperText={
              !repeatedPass
                ? ''
                : repeatedPass === password
                ? ''
                : 'رمز عبور یکسان نیست'
            }
            onChange={(val) => {
              setRepeatedPass(val)
            }}
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!code || loading}
            onClick={onClickButton}>
            {'تایید'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerificationCodeForPassword

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    // paddingTop: 150,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  image: {
    width: '100%'
  },
  texts: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textSecondLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttons: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    width: '14rem!important',
    borderRadius: '1rem!important',
    fontSize: '1.25rem!important'
  },
  secondButton: {
    marginTop: '1rem!important',
    background: `${theme.palette.primary.light}!important`,
    color: `${theme.palette.primary.main}!important`
  }
}))

import { Typography } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useStyles } from './styles/index'
import { CartItem } from '../../types/favorites'

type Props = {
  item: CartItem
}

function FavoriteItem({ item }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.upSection}>
        <img
          src={item.product.photo}
          alt="user-image-profile"
          className={classes.userAvatar}
        />
        <Typography className={classes.productName}>
          {item.product.title}
        </Typography>
        <FavoriteIcon className={classes.icon} />
      </div>
    </div>
  )
}

export default FavoriteItem

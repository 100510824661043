import { useParams } from 'react-router-dom'
import CoinCard, { CoinCardItem } from '../../components/CoinCard'
import Header from '../../components/Header'
import { getTranslate } from '../../helpers/translate'
import { useEffect, useState } from 'react'
import { apiRequest } from '../../helpers/request'
import {
  CoinListApiResponse,
  OrderRequest,
  OrderResponse
} from '../../types/coin'
import { toast } from 'react-toastify'
import LoadingScreen from '../../components/LoadingScreen'
import { useStyles } from './styles/index'

function BuyCoinList() {
  const classes = useStyles()
  const { subpage } = useParams()
  const [coins, setcoins] = useState<CoinCardItem[]>([])
  const [loading, setLoading] = useState(false)

  const onClickBuyCoinButton = async (body: OrderRequest) => {
    const { data, error } = await apiRequest<OrderResponse>('/api/ordermc', {
      method: 'POST',
      body: body
    })

    if (data?.status) {
      toast.success('محصول مورد نظر به سفارشات اضافه شد')
    } else {
      toast.error(error || 'Buy failed. Please try again.')
    }
  }

  const fetchMenuItems = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<CoinListApiResponse>(
      '/api/coin?type=' + (subpage === 'imami-coin' ? 'emami' : 'parsian'),
      {
        method: 'GET'
      }
    )
    setLoading(false)

    if (data?.status) {
      setcoins(
        data.data.data.map((coin) => ({
          code: coin.id.toString(),
          imageURL: 'https://panel.zozangallery.com/' + coin.photo,
          onClick: () => {
            onClickBuyCoinButton({
              order_type: 'buy',
              product_id: coin.id,
              quantity: 1,
              type: 'coin'
            })
          },
          price: coin.price_buy,
          title: coin.title
        }))
      )
    } else {
      toast.error(error || 'failed to fetch coins')
    }
  }

  useEffect(() => {
    fetchMenuItems()
  }, [])

  return (
    <div className={classes.root}>
      <Header title={getTranslate(subpage || '')} existIcons />
      {/* TODO: implement header and sort/filter to the tope of menuItems */}
      <div className={classes.menuItemsContainer}>
        {coins.map((item, index) => (
          <CoinCard key={index} data={item} />
        ))}
      </div>
      <LoadingScreen numberOfCircles={4} open={loading} />
    </div>
  )
}

export default BuyCoinList

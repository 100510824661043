import { Button } from '@mui/material'
import Header from '../../components/Header'
import TextAreaInput from '../../components/TextAreaInput'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useStyles } from './styles/index'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import { OTPResponse } from '../../types/otp'

function ForgotPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState('')

  const onClickContinueButton = async () => {
    const { data, error } = await apiRequest<OTPResponse>('/api/request-otp', {
      method: 'POST',
      body: {
        phone: phoneNumber,
        type: 'forget'
      }
    })

    if (data?.status) {
      toast.success(data.message)
      localStorage.setItem('mobile', phoneNumber)
      navigate('/verify-code-password')
    } else {
      toast.error(error || 'Sending OTP failed. Please try again.')
    }
  }

  var regex = /^09\d{9}$/
  var result = regex.test(phoneNumber)

  return (
    <div className={classes.root}>
      <Header title={'فراموشی رمز عبور'} />
      <div className={classes.inputs}>
        <TextAreaInput
          title={'شماره تلفن '}
          placeholder={'شماره تلفن خود را وارد نمایید'}
          type={'number'}
          value={phoneNumber}
          onChange={(val) => {
            setPhoneNumber(val)
          }}
        />
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!result}
            onClick={onClickContinueButton}>
            {'ادامه'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

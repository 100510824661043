export const getTranslate = (input: string) => {
  switch (input) {
    case 'imami-coin':
      return 'سکه امامی'
    case 'parsian-coin':
      return 'سکه پارسیان'
    case 'women-jewelry':
      return 'جواهرات زنان'
    case 'men-jewelry':
      return 'جواهرات مردان'
    case 'women-kid':
      return 'جواهرات کودک'
    case 'special-offer-jewelry':
      return 'جواهرات پیشنهاد ویژه'
    case 'all':
      return 'همه محصولات'
    default:
      return ''
  }
}

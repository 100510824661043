import { Button, Typography } from '@mui/material'
import logo from '../../assets/welcome.jpg'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles/index'

function Welcome() {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClickOnLogin = () => {
    navigate('/login')
  }

  const handleClickOnSignUp = () => {
    navigate('/sign-up')
  }

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.image} />
      <div className={classes.texts}>
        <Typography variant="h2">{'پلتفرم خرید و فروش'}</Typography>
        <div className={classes.textSecondLine}>
          <Typography variant="h2">{'ززان'}</Typography>&nbsp;
          <Typography variant="h2">{'طلا'}</Typography>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleClickOnLogin}>
          {'ورود'}
        </Button>
        <Button
          className={[classes.button, classes.secondButton].join(' ')}
          variant="contained"
          onClick={handleClickOnSignUp}>
          {'ثبت نام'}
        </Button>
      </div>
    </div>
  )
}

export default Welcome

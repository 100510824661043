import { Button, Typography } from '@mui/material'
import Header from '../../components/Header'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import TextAreaInput from '../../components/TextAreaInput'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SignUpInputs, SignUpResponse } from '../../types/signUp'
import { apiRequest } from '../../helpers/request'
import { toast } from 'react-toastify'
import Stepper from '../../components/stepper/stepper'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMomentJalaali } from '@mui/x-date-pickers/AdapterMomentJalaali'
import { faIR } from '@mui/x-date-pickers/locales'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment-jalaali'
import { OTPResponse } from '../../types/otp'
import Checkbox from '@mui/material/Checkbox'
import { useStyles } from './styles/index'

function SignUp() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [inputs, setInputs] = useState<SignUpInputs>({
    mobile: '',
    password: '',
    name: '',
    lastName: '',
    nationalCode: '',
    birthDate: ''
  })

  const [activeStep, setActiveStep] = useState(0)
  const [repeatedPass, setRepeatedPass] = useState('')
  const [value, setValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [checkBox, setCheckBox] = useState(false)

  var regex = /^09\d{9}$/
  var phoneNumberResult = regex.test(inputs.mobile)

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/
  const isValidPassword = (password: string): boolean => {
    return passwordRegex.test(password)
  }

  const nationalCodeRegex = /^(?!0+$)\d{10}$/
  var nationalCodeResult = nationalCodeRegex.test(inputs.nationalCode)

  const steps = ['شماره تلفن', 'کد فعال‌سازی', 'رمز عبور', 'احراز هویت']
  const eighteenYearsAgo = moment()
    .subtract(18, 'years')
    .add(1, 'days')
    .format('YYYY/MM/DD')

  const handleClickInput = () => {
    setIsVisible(!isVisible)
  }

  const handleClickOnLogin = () => {
    navigate('/login')
  }

  const onClickButton = async () => {
    setLoading(true)
    const { data } = await apiRequest<OTPResponse>('/api/request-otp', {
      method: 'POST',
      body: {
        phone: inputs.mobile,
        type: 'login'
      }
    })
    setLoading(false)

    if (data?.status) {
      toast.success('کد با موفقیت ارسال شد')
      setActiveStep(1)
    } else {
      toast.error('در ارسال کد مشکلی رخ داده، دوباره تلاش کنید.')
    }
  }

  const handleChange = (newValue: string) => {
    setValue(newValue)
  }

  const handleComplete = async (finalValue: string) => {
    const { data, error } = await apiRequest<SignUpResponse>(
      '/api/verify-otp',
      {
        method: 'POST',
        body: {
          phone: inputs.mobile,
          otp: finalValue
        }
      }
    )

    if (data?.status) {
      toast.success('کد با موفقیت ثبت شد')
      setActiveStep(2)
    } else {
      toast.error('مشکلی در ثبت کد رخ داده است')
    }
  }

  function matchIsNumeric(text: string) {
    const isNumber = typeof text === 'number'
    const isString = typeof text === 'string'
    return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
  }

  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value)
  }

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      // Convert the selected date to Gregorian and then to a formatted string
      const gregorianDate = date.format('YYYY/MM/DD')
      setInputs({
        ...inputs,
        birthDate: gregorianDate
      })
    } else {
      setInputs({
        ...inputs,
        birthDate: ''
      })
    }
  }

  const onClickRegister = async () => {
    setLoading(true)
    const { data, error } = await apiRequest<SignUpResponse>('/api/register', {
      method: 'POST',
      body: inputs
    })

    setLoading(false)

    if (data?.status) {
      toast.success('اکانت با موفقیت ساخته شد.')
      navigate('/login')
    } else {
      toast.error('مشکلی در ساخت اکانت رخ داده است، دوباره تلاش کنید')
    }
  }

  const content = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div className={classes.inputs}>
            <TextAreaInput
              title={'شماره تلفن '}
              placeholder={'شماره تلفن خود را وارد نمایید'}
              type={'number'}
              value={inputs.mobile}
              onChange={(val) => {
                setInputs({
                  ...inputs,
                  mobile: val
                })
              }}
            />
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={!phoneNumberResult || loading}
                onClick={onClickButton}>
                {'تایید و ادامه'}
              </Button>
            </div>
            <div className={classes.alreadyHaveAcc}>
              <Typography
                variant="overline"
                className={classes.login}
                onClick={handleClickOnLogin}>
                {'ورود'}
              </Typography>
              <Typography variant="overline">
                {'آیا حساب کاربری دارید؟'}
              </Typography>
            </div>
          </div>
        )
      case 1:
        return (
          <div className={classes.inputs}>
            <Typography variant="h4" className={classes.title}>
              {'کد فعال‌سازی'}
            </Typography>
            <Typography className={classes.title2}>
              {`کد ارسال شده به شماره ${inputs.mobile} را وارد کنید.`}
            </Typography>
            <div className={classes.title}>
              <Typography
                variant="overline"
                className={classes.modify}
                onClick={() => {
                  setActiveStep(0)
                }}>
                {'ویرایش شماره'}
              </Typography>
            </div>
            <div className={classes.otpInputs}>
              <MuiOtpInput
                value={value}
                onChange={handleChange}
                onComplete={handleComplete}
                length={6}
                autoFocus
                dir="ltr"
                validateChar={validateChar}
                TextFieldsProps={{
                  type: 'number',
                  inputProps: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }
                }}
                gap={1}
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className={classes.inputs}>
            <Typography variant="h4" className={classes.title}>
              {'تعیین رمز عبور'}
            </Typography>
            <Typography className={classes.title}>
              {'یک رمز برای حساب کاربری خود انتخاب کنید.'}
            </Typography>
            <TextAreaInput
              title={'کلمه عبور'}
              placeholder={'کلمه عبور خود را وارد نمایید'}
              icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              onClick={handleClickInput}
              type={isVisible ? 'text' : 'password'}
              value={inputs.password}
              helperText={
                isValidPassword(inputs.password)
                  ? ''
                  : 'کلمه عبور باید حداقل شامل هشت حرف باشد که یک حرف بزرگ و یه کاراکتر ویژه هم داشته باشد.'
              }
              onChange={(val) => {
                setInputs({
                  ...inputs,
                  password: val
                })
              }}
            />
            <div className={classes.repeatedPass}>
              <TextAreaInput
                title={'تکرار کلمه عبور'}
                placeholder={'کلمه عبور خود را تکرار کنید'}
                icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                onClick={handleClickInput}
                type={isVisible ? 'text' : 'password'}
                value={repeatedPass}
                helperText={
                  !repeatedPass
                    ? ''
                    : repeatedPass === inputs.password
                    ? ''
                    : 'رمز عبور یکسان نیست'
                }
                onChange={(val) => {
                  setRepeatedPass(val)
                }}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={
                  !isValidPassword(inputs.password) ||
                  repeatedPass !== inputs.password
                }
                onClick={() => {
                  setActiveStep(3)
                }}>
                {'تایید و ادامه'}
              </Button>
            </div>
          </div>
        )
      case 3:
        return (
          <div className={classes.inputs}>
            <Typography variant="h4" className={classes.title}>
              {'اطلاعات کاربری'}
            </Typography>
            <Typography className={classes.title}>
              {'اطلاعات خود را وارد کنید.'}
            </Typography>
            <TextAreaInput
              title={'نام'}
              placeholder={'نام خود را وارد نمایید'}
              type={'text'}
              value={inputs.name}
              onChange={(val) => {
                setInputs({
                  ...inputs,
                  name: val
                })
              }}
            />
            <TextAreaInput
              title={'نام خانوادگی'}
              placeholder={'نام خانوادگی خود را وارد نمایید'}
              type={'text'}
              value={inputs.lastName}
              onChange={(val) => {
                setInputs({
                  ...inputs,
                  lastName: val
                })
              }}
            />
            <div>
              <Typography variant="h6">{'تاریخ تولد'}</Typography>
              <LocalizationProvider
                dateAdapter={AdapterMomentJalaali}
                adapterLocale="fa"
                localeText={
                  faIR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }>
                <DatePicker
                  value={
                    inputs.birthDate
                      ? moment(inputs.birthDate, 'YYYY/MM/DD')
                      : null
                  }
                  onChange={handleDateChange}
                  format="jYYYY/jMM/jDD"
                  disableFuture
                  maxDate={moment(eighteenYearsAgo)}
                  sx={{
                    '.MuiInputBase-root': {
                      width: '16rem'
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
            <TextAreaInput
              title={'کد ملی'}
              placeholder={'کد ملی خود (مالک شماره تلفن) را وارد کنید.'}
              helperText={
                !inputs.nationalCode
                  ? ''
                  : nationalCodeResult
                  ? ''
                  : 'کد ملی وارد شده صحیح نمی‌باشد.'
              }
              type={'number'}
              value={inputs.nationalCode}
              onChange={(val) => {
                setInputs({
                  ...inputs,
                  nationalCode: val
                })
              }}
            />
            <div className={classes.rules}>
              <Checkbox
                color="primary"
                checked={checkBox} // Bind the checked prop
                onChange={() => setCheckBox(!checkBox)} // Toggle the state on change
              />
              <span
                className={classes.ruleText}
                onClick={() => {
                  navigate('/rules')
                }}>
                قوانین و مقررات
              </span>
              &nbsp; را خوانده‌ام و با آنها موافق هستم.
            </div>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={
                  !inputs.name ||
                  !inputs.lastName ||
                  !inputs.birthDate ||
                  !inputs.nationalCode ||
                  !nationalCodeResult ||
                  !checkBox ||
                  loading
                }
                onClick={onClickRegister}>
                {'ثبت نام'}
              </Button>
            </div>
          </div>
        )
      default:
        break
    }
  }

  useEffect(() => {
    moment.loadPersian({ usePersianDigits: false })
  }, [])

  return (
    <div className={classes.root}>
      <Header title={'ثبت نام'} />
      <div className={classes.stepperContainer}>
        <Stepper activeStep={activeStep} steps={steps} success />
      </div>
      {content(activeStep)}
    </div>
  )
}

export default SignUp

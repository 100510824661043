import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.light,
    padding: '0.5rem 1.5rem',
    borderRadius: 16,
    width: '16rem'
  },
  upSection: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 1fr'
  },
  userAvatar: {
    width: '5rem',
    height: '5rem'
  },
  productName: {
    paddingRight: '1rem'
  },
  icon: {
    backgroundColor: `${theme.palette.primary.contrastText}!important`,
    color: `${theme.palette.primary.main}!important`,
    fontSize: '2rem!important',
    borderRadius: '50%',
    padding: '0.25rem'
  }
}))

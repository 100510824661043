import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.light,
    padding: '0.5rem 1rem',
    borderRadius: 16
  },
  title: {
    marginBottom: '1rem!important',
    color: theme.palette.primary.main
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between!important',
    marginBottom: '1rem!important',
    width: '100%'
  },
  priceSectionRight: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleSmall: {
    fontSize: '0.625rem!important',
    color: theme.palette.primary.main
  },
  titleMedium: {
    fontSize: '1rem!important',
    color: theme.palette.primary.main
  },
  priceSectionLeft: {
    display: 'flex',
    flexDirection: 'row'
  },
  currency: {
    marginRight: '0.25rem!important'
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  price: {
    textAlign: 'center',
    width: '80%',
    direction: 'rtl',
    fontFamily: 'IranSans',
    borderRadius: '0.5rem',
    padding: '0.5rem 0.5rem',
    border: '1px solid black',
    marginBottom: '0.5rem'
  },
  buttonBuy: {
    borderRadius: '1rem!important',
    background: `${theme.palette.success.dark}!important`,
    padding: '0.4rem 1.5rem !important'
  },
  buttonSell: {
    borderRadius: '1rem!important',
    background: `${theme.palette.error.dark}!important`,
    padding: '0.4rem 1.5rem !important'
  },
  hide: {
    opacity: 0
  }
}))

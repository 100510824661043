import { Typography } from '@mui/material'
import Header from '../../components/Header'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import logo from '../../assets/logo.svg'
import { useNavigate } from 'react-router-dom'
import { ProfileItem } from '../../types/profile'
import { useStyles } from './styles/index'
import { apiRequest } from '../../helpers/request'
import { ProfileResponse, UserInfoInputs } from '../../types/userInfo'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'

function Profile() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [data, setData] = useState<UserInfoInputs>({
    name: '',
    lastName: '',
    birthDate: null,
    nationalCode: '',
    phoneNumber: ''
  })

  const items: ProfileItem[] = [
    {
      title: 'اطلاعات کاربری',
      icon: <PersonOutlineIcon className={classes.icon} />,
      path: '/user-info'
    },
    {
      title: 'علاقه‌مندی‌ها',
      icon: <FavoriteBorderIcon className={classes.icon} />,
      path: '/favorite'
    },
    {
      title: 'تعریف کارت بانکی',
      icon: <CreditCardIcon className={classes.icon} />,
      path: '/bank-cart'
    },
    {
      title: 'قوانین و امنیت',
      icon: <LockOutlinedIcon className={classes.icon} />,
      path: '/rules'
    },
    {
      title: 'تنظیمات',
      icon: <SettingsOutlinedIcon className={classes.icon} />,
      path: 'setting'
    },
    {
      title: 'کیف پول',
      icon: <AccountBalanceWalletOutlinedIcon className={classes.icon} />,
      path: '/wallet'
    },
    {
      title: 'خروج',
      icon: <LogoutOutlinedIcon className={classes.icon} />,
      path: '/welcome'
    }
  ]

  const GetData = async () => {
    const { data, error } = await apiRequest<ProfileResponse>(
      '/api/get-profile',
      {
        method: 'GET'
      }
    )

    if (data?.status) {
      setData({
        name: data.data.name,
        lastName: data.data.lastName,
        birthDate: data.data.birthDate,
        nationalCode: data.data.nationalCode,
        phoneNumber: data.data.mobile
      })
    } else {
      toast.error(error || 'Get profile data failed. Please try again.')
    }
  }

  useEffect(() => {
    GetData()
  }, [])

  return (
    <div className={classes.root}>
      <Header title={'پروفایل من'} />
      <div className={classes.imageSection}>
        <img
          src={logo}
          alt="user-image-profile"
          className={classes.userAvatar}
        />
        <Typography variant="h2">{data.name + ' ' + data.lastName}</Typography>
      </div>
      {items.map((item) => (
        <div
          className={classes.row}
          onClick={() => {
            if (item.path === '/welcome') {
              localStorage.removeItem('authToken')
            }
            navigate(item.path)
          }}>
          <Typography variant="button">{item.title}</Typography>
          <div className={classes.iconContainer}>{item.icon}</div>
        </div>
      ))}
    </div>
  )
}

export default Profile

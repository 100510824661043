import React, { useState, useEffect } from 'react'
import { Box, TextField, Button, Typography } from '@mui/material'
import Header from '../../components/Header'
import { useStyles } from './styles/index'

interface Message {
  sender: 'user' | 'admin'
  text: string
}

const ChatRoom: React.FC = () => {
  const classes = useStyles()
  const [messages, setMessages] = useState<Message[]>([])
  const [inputMessage, setInputMessage] = useState('')

  // Load messages from localStorage on mount
  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages')
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages))
    }
  }, [])

  // Save messages to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages))
  }, [messages])

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      setMessages([...messages, { sender: 'user', text: inputMessage }])
      setInputMessage('') // Clear input field
    }
  }

  return (
    <div className={classes.root}>
      <Header title={'پشتیبانی چت زوزان'} />
      <Box sx={{ p: 2, maxWidth: 600, margin: '0 auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: 400,
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: 2,
            p: 2
          }}>
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent:
                  message.sender === 'user' ? 'flex-end' : 'flex-start'
              }}>
              <Box
                sx={{
                  p: 1,
                  borderRadius: 2,
                  bgcolor:
                    message.sender === 'user' ? 'primary.light' : 'grey.300',
                  maxWidth: '60%'
                }}>
                <Typography>{message.text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="پیام خود را بنویسید"
          />
          <Button variant="contained" onClick={handleSendMessage}>
            {'ارسال'}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default ChatRoom

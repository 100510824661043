import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  favorites: {
    // width: '100%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.main
  },
  favoritesIcon: {
    color: `${theme.palette.primary.main}!important`
  },
  imgContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '6rem'
  },
  favoritesIconBtn: {},
  image: {
    width: '50%'
  },
  title: {
    marginTop: '3rem',
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: '0.6rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 3,
    marginBottom: 30
  },
  code: {
    color: theme.palette.text.secondary,
    paddingTop: 8
  },
  available: {
    color: theme.palette.success.main
  },
  unavailable: {
    color: theme.palette.error.main
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.grey[200],
    marginBottom: 30
  },
  featuresContainer: {
    width: '100%',
    marginBottom: 30
  },
  features: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 5,
    marginTop: 20
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: 5,
    marginBottom: 20,
    gap: 10,
    backgroundColor: theme.palette.background.paper
  },
  featureTitle: {
    color: theme.palette.grey[600]
  },
  addToCartBtn: {
    backgroundColor: `${theme.palette.success.dark}!important`,
    color: theme.palette.common.white,
    borderRadius: 5,
    padding: '0.5rem 1rem',
    marginTop: 10
  },
  additionalItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  ItemsIcon: {
    marginLeft: 4,
    color: theme.palette.info.main
  },
  ItemText: {
    color: theme.palette.info.main
  },
  itemFavcontainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-betweene'
  }
}))

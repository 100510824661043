import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cart: {
    margin: '1rem 0rem'
  },
  form: {
    paddingBottom: '6rem'
  },
  text: {
    fontFamily: 'IranSans'
  },
  buttonContainer: {
    paddingTop: '2rem'
  },
  button: {
    width: '14rem!important',
    borderRadius: '1rem!important',
    fontSize: '1.25rem!important'
  }
}))

import { Button, Tab, Tabs, Typography } from '@mui/material'
import Header from '../../components/Header'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import TextAreaInput from '../../components/TextAreaInput'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginInputs, LoginResponse, LoginWays } from '../../types/login'
import { apiRequest } from '../../helpers/request'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { useStyles } from './styles/index'
import { OTPResponse } from '../../types/otp'

function Login() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [inputs, setInputs] = useState<LoginInputs>({
    mobile: '',
    password: ''
  })
  const [fixedPass, setFixedPass] = useState<LoginWays>('fixedPass')
  const [loading, setLoading] = useState(false)

  const isFixedPass = fixedPass === 'fixedPass'

  const handleChange = (event: React.SyntheticEvent, newValue: LoginWays) => {
    setFixedPass(newValue)
  }

  const handleClickInput = () => {
    setIsVisible(!isVisible)
  }

  const onClickLoginButton = async () => {
    setLoading(true)
    if (!isFixedPass) {
      const { data, error } = await apiRequest<OTPResponse>(
        '/api/request-otp',
        {
          method: 'POST',
          body: {
            phone: inputs.mobile,
            type: 'login' //TODO: forget
          }
        }
      )
      setLoading(false)

      if (data?.status) {
        toast.success(data.message)
        localStorage.setItem('mobile', inputs.mobile)
        navigate('/verify-code')
      } else {
        toast.error(error || 'Sending OTP failed. Please try again.')
      }

      return
    }

    const { data, error } = await apiRequest<LoginResponse>('/api/login', {
      method: 'POST',
      body: inputs
    })
    setLoading(false)

    if (data?.status) {
      toast.success('Login successful!')
      localStorage.setItem('authToken', data.data.token)
      navigate('/home')
    } else {
      toast.error(error || 'Login failed. Please try again.')
    }
  }

  var regex = /^09\d{9}$/
  var mobileResult = regex.test(inputs.mobile)

  return (
    <div className={classes.root}>
      <Header title={'ورود'} />
      <div className={classes.texts}>
        <Typography className={classes.welcomeText} variant="h2">
          {'خوش آمدید'}
        </Typography>
        <Typography variant="body1">
          {'با استفاده از شماره تلفن خود به سیستم ورود نمایید'}
        </Typography>
      </div>
      <Tabs onChange={handleChange} value={fixedPass}>
        <Tab
          label={'رمز ثابت'}
          value={'fixedPass'}
          className={classes.tabText}
        />
        <Tab
          label={'رمز یک بار مصرف'}
          value={'disposable'}
          className={classes.tabText}
        />
      </Tabs>
      <div className={classes.inputs}>
        <TextAreaInput
          title={'شماره تلفن'}
          placeholder={'شماره تلفن خود را وارد نمایید'}
          type={'number'}
          value={inputs.mobile}
          onChange={(val) => {
            setInputs({
              ...inputs,
              mobile: val
            })
          }}
        />
        {isFixedPass && (
          <TextAreaInput
            title={'کلمه عبور'}
            placeholder={'کلمه عبور خود را وارد نمایید'}
            icon={isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onClick={handleClickInput}
            type={isVisible ? 'text' : 'password'}
            forgetPass
            value={inputs.password}
            onChange={(val) => {
              setInputs({
                ...inputs,
                password: val
              })
            }}
          />
        )}
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!mobileResult || loading}
            onClick={onClickLoginButton}>
            {isFixedPass ? 'ورود' : 'دریافت کد'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login

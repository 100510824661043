import { forwardRef, Ref } from "react";
import {
  Route,
  RouteProps,
  LinkProps,
  Link,
  NavLink,
  NavLinkProps,
  Routes,
} from "react-router-dom";

export const LocalizedRoute = ({
  path,
  element,
  ...otherProps
}: RouteProps) => (
  <Routes>
    <Route {...otherProps} path={path} element={element} />
  </Routes>
);

export const LocalizedLink = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ to, ...otherProps }: LinkProps, ref: Ref<HTMLAnchorElement>) => (
    <Link ref={ref} {...otherProps} to={`/${to}`} />
  )
);

export const LocalizedNavLink = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ to, ...otherProps }: NavLinkProps, ref) => (
    <NavLink {...otherProps} to={`/${to}`} />
  )
);

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

interface ApiRequestOptions {
  method?: HttpMethod
  headers?: HeadersInit // Allow various types of headers
  body?: any // You can specify a more specific type based on your needs (e.g., Record<string, any>)
}

interface ApiResponse<T> {
  data: T | null
  error?: string
}

// Utility function to get the token from local storage
export const getToken = (): string | null => {
  return localStorage.getItem('authToken')
}

// Utility function to log out the user (clear storage and redirect)
const handleLogout = () => {
  // Clear token or other user-related data
  localStorage.removeItem('authToken')
  // Optionally redirect to login page
  window.location.href = '/login' // Adjust this to your login route
}

export const apiRequest = async <T>(
  endpoint: string,
  options: ApiRequestOptions = {}
): Promise<ApiResponse<T>> => {
  const { method = 'GET', headers = {}, body } = options

  // Retrieve the token from localStorage
  const token = getToken()

  // Ensure headers is treated as a string-keyed object
  const requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    ...(headers as Record<string, string>) // Cast headers to a string key object
  }

  // Add the Authorization token if available
  if (token) {
    requestHeaders['Authorization'] = `Bearer ${token}`
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
      {
        method,
        headers: requestHeaders, // Use the correctly typed headers object
        body: body ? JSON.stringify(body) : undefined
      }
    )

    // Handle non-OK responses
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized error, clear storage and log out
        handleLogout() //TODO - uncomment this in real
      }

      const errorResponse = await response.json()
      throw new Error(errorResponse.message || 'Something went wrong')
    }

    // Parse and return the response
    const data = (await response.json()) as T
    return { data }
  } catch (error) {
    return {
      data: null,
      error: error instanceof Error ? error.message : 'Unknown error'
    }
  }
}
